import {onMounted} from "vue";
import {AxiosResponse} from "axios";
import Input from "@/types/Input";

export function updateError(errorCount: number, isError: boolean) {
    isError ? errorCount++ : errorCount--;
    console.log(errorCount);
}

export function getForm(response: AxiosResponse, model: any) {
    Object.keys(response.data.value).forEach((category: string) => {
        model[category as keyof typeof model].forEach((input: any) => {
            Object.keys(response.data.value[category]).forEach((backInput: string) => {
                if (input.field === backInput) input.value = response.data.value[category][backInput];
            });
        });
    });
}

export function beautifyName(name: string) {
    switch (name) {
        case 'masque':
            return 'Masque';
        case 'bureautique':
            return 'Bureautique';
        case 'initialisation':
            return 'Initialisation';
        case 'import':
            return 'Import';
        case 'fichierSequentiel':
            return 'Fichier séquentiel';
        case 'provisionComptable':
            return 'Provision comptable';
        case 'activiteJournaliere':
            return 'Activité journalière';
        case 'primes':
            return 'Primes';
        case 'editions':
            return 'Editions';
        default:
            return name;
    }
}

export function formeJuridiquesLst() {
    return [
        {
            name: "0000 Organisme de placement collectif en valeurs mobilières sans personnalité morale",
            id: "0000 Organisme de placement collectif en valeurs mobilières sans personnalité morale",
        },
        {
            name: "1000 Entrepreneur individuel",
            id: "1000 Entrepreneur individuel",
        },
        { name: "1100 Artisan-commerçant", id: "1100 Artisan-commerçant" },
        { name: "1200 Commerçant", id: "1200 Commerçant" },
        { name: "1300 Artisan", id: "1300 Artisan" },
        {
            name: "1400 Officier public ou ministériel",
            id: "1400 Officier public ou ministériel",
        },
        {
            name: "1500 Profession libérale",
            id: "1500 Profession libérale",
        },
        {
            name: "1600 Exploitant agricole",
            id: "1600 Exploitant agricole",
        },
        { name: "1700 Agent commercial", id: "1700 Agent commercial" },
        {
            name: "1800 Associé gérant de Société",
            id: "1800 Associé gérant de Société",
        },
        {
            name: "1900 (Autre) Personne physique",
            id: "1900 (Autre) Personne physique",
        },
        {
            name: "2110 Indivision entre personnes physiques",
            id: "2110 Indivision entre personnes physiques",
        },
        {
            name: "2120 Indivision avec personne morale",
            id: "2120 Indivision avec personne morale",
        },
        {
            name: "2210 Société créée de fait entre personnes physiques",
            id: "2210 Société créée de fait entre personnes physiques",
        },
        {
            name: "2220 Société créée de fait avec personne morale",
            id: "2220 Société créée de fait avec personne morale",
        },
        {
            name: "2310 Société en participation entre personnes physiques",
            id: "2310 Société en participation entre personnes physiques",
        },
        {
            name: "2320 Société en participation avec personne morale",
            id: "2320 Société en participation avec personne morale",
        },
        {
            name: "2385 Société en participation de professions libérales",
            id: "2385 Société en participation de professions libérales",
        },
        { name: "2400 Fiducie", id: "2400 Fiducie" },
        {
            name: "2700 Paroisse hors zone concordataire",
            id: "2700 Paroisse hors zone concordataire",
        },
        {
            name: "2900 Autre groupement de droit privé non doté de la personnalité morale",
            id: "2900 Autre groupement de droit privé non doté de la personnalité morale",
        },
        {
            name: "3110 Représentation ou agence commerciale d'état ou organisme public étranger immatriculé au RCS",
            id: "3110 Représentation ou agence commerciale d'état ou organisme public étranger immatriculé au RCS",
        },
        {
            name: "3120 Société commerciale étrangère immatriculée au RCS",
            id: "3120 Société commerciale étrangère immatriculée au RCS",
        },
        {
            name: "3205 Organisation internationale",
            id: "3205 Organisation internationale",
        },
        {
            name: "3210 État, collectivité ou établissement public étranger",
            id: "3210 État, collectivité ou établissement public étranger",
        },
        {
            name: "3220 Société étrangère non immatriculée au RCS",
            id: "3220 Société étrangère non immatriculée au RCS",
        },
        {
            name: "3290 Autre personne morale de droit étranger",
            id: "3290 Autre personne morale de droit étranger",
        },
        {
            name: "4110 Établissement public national à caractère industriel ou commercial doté d'un comptable public",
            id: "4110 Établissement public national à caractère industriel ou commercial doté d'un comptable public",
        },
        {
            name: "4120 Établissement public national à caractère industriel ou commercial non doté d'un comptable public",
            id: "4120 Établissement public national à caractère industriel ou commercial non doté d'un comptable public",
        },
        { name: "4130 Exploitant public", id: "4130 Exploitant public" },
        {
            name: "4140 Établissement public local à caractère industriel ou commercial",
            id: "4140 Établissement public local à caractère industriel ou commercial",
        },
        {
            name: "4150 Régie d'une collectivité locale à caractère industriel ou commercial",
            id: "4150 Régie d'une collectivité locale à caractère industriel ou commercial",
        },
        {
            name: "4160 Institution Banque de France",
            id: "4160 Institution Banque de France",
        },
        {
            name: "5191 Société de caution mutuelle",
            id: "5191 Société de caution mutuelle",
        },
        {
            name: "5192 Société coopérative de banque populaire",
            id: "5192 Société coopérative de banque populaire",
        },
        {
            name: "5193 Caisse de crédit maritime mutuel",
            id: "5193 Caisse de crédit maritime mutuel",
        },
        {
            name: "5194 Caisse (fédérale) de crédit mutuel",
            id: "5194 Caisse (fédérale) de crédit mutuel",
        },
        {
            name: "5195 Association coopérative inscrite (droit local Alsace Moselle)",
            id: "5195 Association coopérative inscrite (droit local Alsace Moselle)",
        },
        {
            name: "5196 Caisse d'épargne et de prévoyance à forme coopérative",
            id: "5196 Caisse d'épargne et de prévoyance à forme coopérative",
        },
        {
            name: "5202 Société en nom collectif",
            id: "5202 Société en nom collectif",
        },
        {
            name: "5203 Société en nom collectif coopérative",
            id: "5203 Société en nom collectif coopérative",
        },
        {
            name: "5306 Société en commandite simple",
            id: "5306 Société en commandite simple",
        },
        {
            name: "5307 Société en commandite simple coopérative",
            id: "5307 Société en commandite simple coopérative",
        },
        {
            name: "5308 Société en commandite par actions",
            id: "5308 Société en commandite par actions",
        },
        {
            name: "5309 Société en commandite par actions coopérative",
            id: "5309 Société en commandite par actions coopérative",
        },
        {
            name: "5310 Société en libre partenariat (SLP)",
            id: "5310 Société en libre partenariat (SLP)",
        },
        {
            name: "5370 Société de Participations Financières de Profession Libérale Société en commandite par actions (SPFPL SCA)",
            id: "5370 Société de Participations Financières de Profession Libérale Société en commandite par actions (SPFPL SCA)",
        },
        {
            name: "5385 Société d'exercice libéral en commandite par actions",
            id: "5385 Société d'exercice libéral en commandite par actions",
        },
        { name: "5410 SARL nationale", id: "5410 SARL nationale" },
        {
            name: "5415 SARL d'économie mixte",
            id: "5415 SARL d'économie mixte",
        },
        {
            name: "5422 SARL immobilière pour le commerce et l'industrie (SICOMI)",
            id: "5422 SARL immobilière pour le commerce et l'industrie (SICOMI)",
        },
        {
            name: "5426 SARL immobilière de gestion",
            id: "5426 SARL immobilière de gestion",
        },
        {
            name: "5430 SARL d'aménagement foncier et d'équipement rural (SAFER)",
            id: "5430 SARL d'aménagement foncier et d'équipement rural (SAFER)",
        },
        {
            name: "5431 SARL mixte d'intérêt agricole (SMIA)",
            id: "5431 SARL mixte d'intérêt agricole (SMIA)",
        },
        {
            name: "5432 SARL d'intérêt collectif agricole (SICA)",
            id: "5432 SARL d'intérêt collectif agricole (SICA)",
        },
        { name: "5442 SARL d'attribution", id: "5442 SARL d'attribution" },
        {
            name: "5443 SARL coopérative de construction",
            id: "5443 SARL coopérative de construction",
        },
        {
            name: "5451 SARL coopérative de consommation",
            id: "5451 SARL coopérative de consommation",
        },
        {
            name: "5453 SARL coopérative artisanale",
            id: "5453 SARL coopérative artisanale",
        },
        {
            name: "5454 SARL coopérative d'intérêt maritime",
            id: "5454 SARL coopérative d'intérêt maritime",
        },
        {
            name: "5455 SARL coopérative de transport",
            id: "5455 SARL coopérative de transport",
        },
        {
            name: "5458 SARL coopérative ouvrière de production (SCOP)",
            id: "5458 SARL coopérative ouvrière de production (SCOP)",
        },
        {
            name: "5459 SARL union de sociétés coopératives",
            id: "5459 SARL union de sociétés coopératives",
        },
        {
            name: "5460 Autre SARL coopérative",
            id: "5460 Autre SARL coopérative",
        },
        {
            name: "5470 Société de Participations Financières de Profession Libérale Société à responsabilité limitée (SPFPL SARL)",
            id: "5470 Société de Participations Financières de Profession Libérale Société à responsabilité limitée (SPFPL SARL)",
        },
        {
            name: "5485 Société d'exercice libéral à responsabilité limitée",
            id: "5485 Société d'exercice libéral à responsabilité limitée",
        },
        {
            name: "5498 SARL unipersonnelle",
            id: "5498 SARL unipersonnelle",
        },
        {
            name: "5499 Société à responsabilité limitée (sans autre indication)",
            id: "5499 Société à responsabilité limitée (sans autre indication)",
        },
        {
            name: "5505 SA à participation ouvrière à conseil d'administration",
            id: "5505 SA à participation ouvrière à conseil d'administration",
        },
        {
            name: "5510 SA nationale à conseil d'administration",
            id: "5510 SA nationale à conseil d'administration",
        },
        {
            name: "5515 SA d'économie mixte à conseil d'administration",
            id: "5515 SA d'économie mixte à conseil d'administration",
        },
        {
            name: "5520 Fonds à forme sociétale à conseil d'administration",
            id: "5520 Fonds à forme sociétale à conseil d'administration",
        },
        {
            name: "5522 SA immobilière pour le commerce et l'industrie (SICOMI) à conseil d'administration",
            id: "5522 SA immobilière pour le commerce et l'industrie (SICOMI) à conseil d'administration",
        },
        {
            name: "5525 SA immobilière d'investissement à conseil d'administration",
            id: "5525 SA immobilière d'investissement à conseil d'administration",
        },
        {
            name: "5530 SA d'aménagement foncier et d'équipement rural (SAFER) à conseil d'administration",
            id: "5530 SA d'aménagement foncier et d'équipement rural (SAFER) à conseil d'administration",
        },
        {
            name: "5531 Société anonyme mixte d'intérêt agricole (SMIA) à conseil d'administration",
            id: "5531 Société anonyme mixte d'intérêt agricole (SMIA) à conseil d'administration",
        },
        {
            name: "5532 SA d'intérêt collectif agricole (SICA) à conseil d'administration",
            id: "5532 SA d'intérêt collectif agricole (SICA) à conseil d'administration",
        },
        {
            name: "5542 SA d'attribution à conseil d'administration",
            id: "5542 SA d'attribution à conseil d'administration",
        },
        {
            name: "5543 SA coopérative de construction à conseil d'administration",
            id: "5543 SA coopérative de construction à conseil d'administration",
        },
        {
            name: "5546 SA de HLM à conseil d'administration",
            id: "5546 SA de HLM à conseil d'administration",
        },
        {
            name: "5547 SA coopérative de production de HLM à conseil d'administration",
            id: "5547 SA coopérative de production de HLM à conseil d'administration",
        },
        {
            name: "5548 SA de crédit immobilier à conseil d'administration",
            id: "5548 SA de crédit immobilier à conseil d'administration",
        },
        {
            name: "5551 SA coopérative de consommation à conseil d'administration",
            id: "5551 SA coopérative de consommation à conseil d'administration",
        },
        {
            name: "5552 SA coopérative de commerçants-détaillants à conseil d'administration",
            id: "5552 SA coopérative de commerçants-détaillants à conseil d'administration",
        },
        {
            name: "5553 SA coopérative artisanale à conseil d'administration",
            id: "5553 SA coopérative artisanale à conseil d'administration",
        },
        {
            name: "5554 SA coopérative (d'intérêt) maritime à conseil d'administration",
            id: "5554 SA coopérative (d'intérêt) maritime à conseil d'administration",
        },
        {
            name: "5555 SA coopérative de transport à conseil d'administration",
            id: "5555 SA coopérative de transport à conseil d'administration",
        },
        {
            name: "5558 SA coopérative ouvrière de production (SCOP) à conseil d'administration",
            id: "5558 SA coopérative ouvrière de production (SCOP) à conseil d'administration",
        },
        {
            name: "5559 SA union de sociétés coopératives à conseil d'administration",
            id: "5559 SA union de sociétés coopératives à conseil d'administration",
        },
        {
            name: "5560 Autre SA coopérative à conseil d'administration",
            id: "5560 Autre SA coopérative à conseil d'administration",
        },
        {
            name: "5570 Société de Participations Financières de Profession Libérale Société anonyme à conseil d'administration (SPFPL SA à conseil d'administration)",
            id: "5570 Société de Participations Financières de Profession Libérale Société anonyme à conseil d'administration (SPFPL SA à conseil d'administration)",
        },
        {
            name: "5585 Société d'exercice libéral à forme anonyme à conseil d'administration",
            id: "5585 Société d'exercice libéral à forme anonyme à conseil d'administration",
        },
        {
            name: "5599 SA à conseil d'administration (s.a.i.)",
            id: "5599 SA à conseil d'administration (s.a.i.)",
        },
        {
            name: "5605 SA à participation ouvrière à directoire",
            id: "5605 SA à participation ouvrière à directoire",
        },
        {
            name: "5610 SA nationale à directoire",
            id: "5610 SA nationale à directoire",
        },
        {
            name: "5615 SA d'économie mixte à directoire",
            id: "5615 SA d'économie mixte à directoire",
        },
        {
            name: "5620  Fonds à forme sociétale à directoire",
            id: "5620  Fonds à forme sociétale à directoire",
        },
        {
            name: "5622 SA immobilière pour le commerce et l'industrie (SICOMI) à directoire",
            id: "5622 SA immobilière pour le commerce et l'industrie (SICOMI) à directoire",
        },
        {
            name: "5625 SA immobilière d'investissement à directoire",
            id: "5625 SA immobilière d'investissement à directoire",
        },
        {
            name: "5630 Safer anonyme à directoire",
            id: "5630 Safer anonyme à directoire",
        },
        {
            name: "5631 SA mixte d'intérêt agricole (SMIA)",
            id: "5631 SA mixte d'intérêt agricole (SMIA)",
        },
        {
            name: "5632 SA d'intérêt collectif agricole (SICA)",
            id: "5632 SA d'intérêt collectif agricole (SICA)",
        },
        {
            name: "5642 SA d'attribution à directoire",
            id: "5642 SA d'attribution à directoire",
        },
        {
            name: "5643 SA coopérative de construction à directoire",
            id: "5643 SA coopérative de construction à directoire",
        },
        {
            name: "5646 SA de HLM à directoire",
            id: "5646 SA de HLM à directoire",
        },
        {
            name: "5647 Société coopérative de production de HLM anonyme à directoire",
            id: "5647 Société coopérative de production de HLM anonyme à directoire",
        },
        {
            name: "5648 SA de crédit immobilier à directoire",
            id: "5648 SA de crédit immobilier à directoire",
        },
        {
            name: "5651 SA coopérative de consommation à directoire",
            id: "5651 SA coopérative de consommation à directoire",
        },
        {
            name: "5652 SA coopérative de commerçants-détaillants à directoire",
            id: "5652 SA coopérative de commerçants-détaillants à directoire",
        },
        {
            name: "5653 SA coopérative artisanale à directoire",
            id: "5653 SA coopérative artisanale à directoire",
        },
        {
            name: "5654 SA coopérative d'intérêt maritime à directoire",
            id: "5654 SA coopérative d'intérêt maritime à directoire",
        },
        {
            name: "5655 SA coopérative de transport à directoire",
            id: "5655 SA coopérative de transport à directoire",
        },
        {
            name: "5658 SA coopérative ouvrière de production (SCOP) à directoire",
            id: "5658 SA coopérative ouvrière de production (SCOP) à directoire",
        },
        {
            name: "5659 SA union de sociétés coopératives à directoire",
            id: "5659 SA union de sociétés coopératives à directoire",
        },
        {
            name: "5660 Autre SA coopérative à directoire",
            id: "5660 Autre SA coopérative à directoire",
        },
        {
            name: "5670 Société de Participations Financières de Profession Libérale Société anonyme à Directoire (SPFPL SA à directoire)",
            id: "5670 Société de Participations Financières de Profession Libérale Société anonyme à Directoire (SPFPL SA à directoire)",
        },
        {
            name: "5685 Société d'exercice libéral à forme anonyme à directoire",
            id: "5685 Société d'exercice libéral à forme anonyme à directoire",
        },
        {
            name: "5699 SA à directoire (s.a.i.)",
            id: "5699 SA à directoire (s.a.i.)",
        },
        {
            name: "5710 SAS, société par actions simplifiée",
            id: "5710 SAS, société par actions simplifiée",
        },
        {
            name: "5720 Société par actions simplifiée à associé unique ou société par actions simplifiée unipersonnelle",
            id: "5720 Société par actions simplifiée à associé unique ou société par actions simplifiée unipersonnelle",
        },
        {
            name: "5770 Société de Participations Financières de Profession Libérale Société par actions simplifiée (SPFPL SAS)",
            id: "5770 Société de Participations Financières de Profession Libérale Société par actions simplifiée (SPFPL SAS)",
        },
        {
            name: "5785 Société d'exercice libéral par action simplifiée",
            id: "5785 Société d'exercice libéral par action simplifiée",
        },
        { name: "5800 Société européenne", id: "5800 Société européenne" },
        {
            name: "6100 Caisse d'Épargne et de Prévoyance",
            id: "6100 Caisse d'Épargne et de Prévoyance",
        },
        {
            name: "6210 Groupement européen d'intérêt économique (GEIE)",
            id: "6210 Groupement européen d'intérêt économique (GEIE)",
        },
        {
            name: "6220 Groupement d'intérêt économique (GIE)",
            id: "6220 Groupement d'intérêt économique (GIE)",
        },
        {
            name: "6316 Coopérative d'utilisation de matériel agricole en commun (CUMA)",
            id: "6316 Coopérative d'utilisation de matériel agricole en commun (CUMA)",
        },
        {
            name: "6317 Société coopérative agricole",
            id: "6317 Société coopérative agricole",
        },
        {
            name: "6318 Union de sociétés coopératives agricoles",
            id: "6318 Union de sociétés coopératives agricoles",
        },
        {
            name: "6411 Société d'assurance à forme mutuelle",
            id: "6411 Société d'assurance à forme mutuelle",
        },
        {
            name: "6511 Sociétés Interprofessionnelles de Soins Ambulatoires",
            id: "6511 Sociétés Interprofessionnelles de Soins Ambulatoires",
        },
        {
            name: "6521 Société civile de placement collectif immobilier (SCPI)",
            id: "6521 Société civile de placement collectif immobilier (SCPI)",
        },
        {
            name: "6532 Société civile d'intérêt collectif agricole (SICA)",
            id: "6532 Société civile d'intérêt collectif agricole (SICA)",
        },
        {
            name: "6533 Groupement agricole d'exploitation en commun (GAEC)",
            id: "6533 Groupement agricole d'exploitation en commun (GAEC)",
        },
        {
            name: "6534 Groupement foncier agricole",
            id: "6534 Groupement foncier agricole",
        },
        {
            name: "6535 Groupement agricole foncier",
            id: "6535 Groupement agricole foncier",
        },
        {
            name: "6536 Groupement forestier",
            id: "6536 Groupement forestier",
        },
        {
            name: "6537 Groupement pastoral",
            id: "6537 Groupement pastoral",
        },
        {
            name: "6538 Groupement foncier et rural",
            id: "6538 Groupement foncier et rural",
        },
        {
            name: "6539 Société civile foncière",
            id: "6539 Société civile foncière",
        },
        {
            name: "6540 Société civile immobilière",
            id: "6540 Société civile immobilière",
        },
        {
            name: "6541 Société civile immobilière de construction-vente",
            id: "6541 Société civile immobilière de construction-vente",
        },
        {
            name: "6542 Société civile d'attribution",
            id: "6542 Société civile d'attribution",
        },
        {
            name: "6543 Société civile coopérative de construction",
            id: "6543 Société civile coopérative de construction",
        },
        {
            name: "6544 Société civile immobilière d' accession progressive à la propriété",
            id: "6544 Société civile immobilière d' accession progressive à la propriété",
        },
        {
            name: "6551 Société civile coopérative de consommation",
            id: "6551 Société civile coopérative de consommation",
        },
        {
            name: "6554 Société civile coopérative d'intérêt maritime",
            id: "6554 Société civile coopérative d'intérêt maritime",
        },
        {
            name: "6558 Société civile coopérative entre médecins",
            id: "6558 Société civile coopérative entre médecins",
        },
        {
            name: "6560 Autre société civile coopérative",
            id: "6560 Autre société civile coopérative",
        },
        { name: "6561 SCP d'avocats", id: "6561 SCP d'avocats" },
        {
            name: "6562 SCP d'avocats aux conseils",
            id: "6562 SCP d'avocats aux conseils",
        },
        {
            name: "6563 SCP d'avoués d'appel",
            id: "6563 SCP d'avoués d'appel",
        },
        { name: "6564 SCP d'huissiers", id: "6564 SCP d'huissiers" },
        { name: "6565 SCP de notaires", id: "6565 SCP de notaires" },
        {
            name: "6566 SCP de commissaires-priseurs",
            id: "6566 SCP de commissaires-priseurs",
        },
        {
            name: "6567 SCP de greffiers de tribunal de commerce",
            id: "6567 SCP de greffiers de tribunal de commerce",
        },
        {
            name: "6568 SCP de conseils juridiques",
            id: "6568 SCP de conseils juridiques",
        },
        {
            name: "6569 SCP de commissaires aux comptes",
            id: "6569 SCP de commissaires aux comptes",
        },
        { name: "6571 SCP de médecins", id: "6571 SCP de médecins" },
        { name: "6572 SCP de dentistes", id: "6572 SCP de dentistes" },
        { name: "6573 SCP d'infirmiers", id: "6573 SCP d'infirmiers" },
        {
            name: "6574 SCP de masseurs-kinésithérapeutes",
            id: "6574 SCP de masseurs-kinésithérapeutes",
        },
        {
            name: "6575 SCP de directeurs de laboratoire d'analyse médicale",
            id: "6575 SCP de directeurs de laboratoire d'analyse médicale",
        },
        {
            name: "6576 SCP de vétérinaires",
            id: "6576 SCP de vétérinaires",
        },
        {
            name: "6577 SCP de géomètres experts",
            id: "6577 SCP de géomètres experts",
        },
        { name: "6578 SCP d'architectes", id: "6578 SCP d'architectes" },
        {
            name: "6585 Autre société civile professionnelle",
            id: "6585 Autre société civile professionnelle",
        },
        {
            name: "6588 Société civile laitière",
            id: "6588 Société civile laitière",
        },
        {
            name: "6589 Société civile de moyens",
            id: "6589 Société civile de moyens",
        },
        {
            name: "6595 Caisse locale de crédit mutuel",
            id: "6595 Caisse locale de crédit mutuel",
        },
        {
            name: "6596 Caisse de crédit agricole mutuel",
            id: "6596 Caisse de crédit agricole mutuel",
        },
        {
            name: "6597 Société civile d'exploitation agricole",
            id: "6597 Société civile d'exploitation agricole",
        },
        {
            name: "6598 Exploitation agricole à responsabilité limitée",
            id: "6598 Exploitation agricole à responsabilité limitée",
        },
        {
            name: "6599 Autre société civile",
            id: "6599 Autre société civile",
        },
        {
            name: "69 Autre personne morale de droit privé inscrite au registre du commerce et des sociétés",
            id: "69 Autre personne morale de droit privé inscrite au registre du commerce et des sociétés",
        },
        {
            name: "6901 Autre personne de droit privé inscrite au registre du commerce et des sociétés",
            id: "6901 Autre personne de droit privé inscrite au registre du commerce et des sociétés",
        },
        {
            name: "7111 Autorité constitutionnelle",
            id: "7111 Autorité constitutionnelle",
        },
        {
            name: "7112 Autorité administrative ou publique indépendante",
            id: "7112 Autorité administrative ou publique indépendante",
        },
        { name: "7113 Ministère", id: "7113 Ministère" },
        {
            name: "7120 Service central d'un ministère",
            id: "7120 Service central d'un ministère",
        },
        {
            name: "7150 Service du ministère de la Défense",
            id: "7150 Service du ministère de la Défense",
        },
        {
            name: "7160 Service déconcentré à compétence nationale d'un ministère (hors Défense)",
            id: "7160 Service déconcentré à compétence nationale d'un ministère (hors Défense)",
        },
        {
            name: "7171 Service déconcentré de l'État à compétence (inter) régionale",
            id: "7171 Service déconcentré de l'État à compétence (inter) régionale",
        },
        {
            name: "7172 Service déconcentré de l'État à compétence (inter) départementale",
            id: "7172 Service déconcentré de l'État à compétence (inter) départementale",
        },
        {
            name: "7179 (Autre) Service déconcentré de l'État à compétence territoriale",
            id: "7179 (Autre) Service déconcentré de l'État à compétence territoriale",
        },
        {
            name: "7190 Ecole nationale non dotée de la personnalité morale",
            id: "7190 Ecole nationale non dotée de la personnalité morale",
        },
        {
            name: "7210 Commune et commune nouvelle",
            id: "7210 Commune et commune nouvelle",
        },
        { name: "7220 Département", id: "7220 Département" },
        {
            name: "7225 Collectivité et territoire d'Outre Mer",
            id: "7225 Collectivité et territoire d'Outre Mer",
        },
        {
            name: "7229 (Autre) Collectivité territoriale",
            id: "7229 (Autre) Collectivité territoriale",
        },
        { name: "7230 Région", id: "7230 Région" },
        {
            name: "7312 Commune associée et commune déléguée",
            id: "7312 Commune associée et commune déléguée",
        },
        { name: "7313 Section de commune", id: "7313 Section de commune" },
        { name: "7314 Ensemble urbain", id: "7314 Ensemble urbain" },
        {
            name: "7321 Association syndicale autorisée",
            id: "7321 Association syndicale autorisée",
        },
        {
            name: "7322 Association foncière urbaine",
            id: "7322 Association foncière urbaine",
        },
        {
            name: "7323 Association foncière de remembrement",
            id: "7323 Association foncière de remembrement",
        },
        {
            name: "7331 Établissement public local d'enseignement",
            id: "7331 Établissement public local d'enseignement",
        },
        { name: "7340 Pôle métropolitain", id: "7340 Pôle métropolitain" },
        { name: "7341 Secteur de commune", id: "7341 Secteur de commune" },
        { name: "7342 District urbain", id: "7342 District urbain" },
        { name: "7343 Communauté urbaine", id: "7343 Communauté urbaine" },
        { name: "7344 Métropole", id: "7344 Métropole" },
        {
            name: "7345 Syndicat intercommunal à vocation multiple (SIVOM)",
            id: "7345 Syndicat intercommunal à vocation multiple (SIVOM)",
        },
        {
            name: "7346 Communauté de communes",
            id: "7346 Communauté de communes",
        },
        {
            name: "7347 Communauté de villes",
            id: "7347 Communauté de villes",
        },
        {
            name: "7348 Communauté d'agglomération",
            id: "7348 Communauté d'agglomération",
        },
        {
            name: "7349 Autre établissement public local de coopération non spécialisé ou entente",
            id: "7349 Autre établissement public local de coopération non spécialisé ou entente",
        },
        {
            name: "7351 Institution interdépartementale ou entente",
            id: "7351 Institution interdépartementale ou entente",
        },
        {
            name: "7352 Institution interrégionale ou entente",
            id: "7352 Institution interrégionale ou entente",
        },
        {
            name: "7353 Syndicat intercommunal à vocation unique (SIVU)",
            id: "7353 Syndicat intercommunal à vocation unique (SIVU)",
        },
        {
            name: "7354 Syndicat mixte fermé",
            id: "7354 Syndicat mixte fermé",
        },
        {
            name: "7355 Syndicat mixte ouvert",
            id: "7355 Syndicat mixte ouvert",
        },
        {
            name: "7356 Commission syndicale pour la gestion des biens indivis des communes",
            id: "7356 Commission syndicale pour la gestion des biens indivis des communes",
        },
        {
            name: "7357 Pôle d'équilibre territorial (PETR)",
            id: "7357 Pôle d'équilibre territorial (PETR)",
        },
        {
            name: "7361 Centre communal d'action sociale",
            id: "7361 Centre communal d'action sociale",
        },
        { name: "7362 Caisse des écoles", id: "7362 Caisse des écoles" },
        {
            name: "7363 Caisse de crédit municipal",
            id: "7363 Caisse de crédit municipal",
        },
        {
            name: "7364 Établissement d'hospitalisation",
            id: "7364 Établissement d'hospitalisation",
        },
        {
            name: "7365 Syndicat inter hospitalier",
            id: "7365 Syndicat inter hospitalier",
        },
        {
            name: "7366 Établissement public local social et médico-social",
            id: "7366 Établissement public local social et médico-social",
        },
        {
            name: "7367 Centre Intercommunal d'action sociale (CIAS)",
            id: "7367 Centre Intercommunal d'action sociale (CIAS)",
        },
        {
            name: "7371 Office public d'habitation à loyer modéré (OPHLM)",
            id: "7371 Office public d'habitation à loyer modéré (OPHLM)",
        },
        {
            name: "7372 Service départemental d'incendie et de secours (SDIS)",
            id: "7372 Service départemental d'incendie et de secours (SDIS)",
        },
        {
            name: "7373 Établissement public local culturel",
            id: "7373 Établissement public local culturel",
        },
        {
            name: "7378 Régie d'une collectivité locale à caractère administratif",
            id: "7378 Régie d'une collectivité locale à caractère administratif",
        },
        {
            name: "7379 (Autre) Établissement public administratif local",
            id: "7379 (Autre) Établissement public administratif local",
        },
        {
            name: "7381 Organisme consulaire",
            id: "7381 Organisme consulaire",
        },
        {
            name: "7382 Établissement public national ayant fonction d'administration centrale",
            id: "7382 Établissement public national ayant fonction d'administration centrale",
        },
        {
            name: "7383 Établissement public national à caractère scientifique culturel et professionnel",
            id: "7383 Établissement public national à caractère scientifique culturel et professionnel",
        },
        {
            name: "7384 Autre établissement public national d'enseignement",
            id: "7384 Autre établissement public national d'enseignement",
        },
        {
            name: "7385 Autre établissement public national administratif à compétence territoriale limitée",
            id: "7385 Autre établissement public national administratif à compétence territoriale limitée",
        },
        {
            name: "7389 Établissement public national à caractère administratif",
            id: "7389 Établissement public national à caractère administratif",
        },
        {
            name: "7410 Groupement d'intérêt public (GIP)",
            id: "7410 Groupement d'intérêt public (GIP)",
        },
        {
            name: "7430 Établissement public des cultes d'Alsace-Lorraine",
            id: "7430 Établissement public des cultes d'Alsace-Lorraine",
        },
        {
            name: "7450 Etablissement public administratif, cercle et foyer dans les armées",
            id: "7450 Etablissement public administratif, cercle et foyer dans les armées",
        },
        {
            name: "7470 Groupement de coopération sanitaire à gestion publique",
            id: "7470 Groupement de coopération sanitaire à gestion publique",
        },
        {
            name: "7490 Autre personne morale de droit administratif",
            id: "7490 Autre personne morale de droit administratif",
        },
        {
            name: "8110 Régime général de la Sécurité Sociale",
            id: "8110 Régime général de la Sécurité Sociale",
        },
        {
            name: "8120 Régime spécial de Sécurité Sociale",
            id: "8120 Régime spécial de Sécurité Sociale",
        },
        {
            name: "8130 Institution de retraite complémentaire",
            id: "8130 Institution de retraite complémentaire",
        },
        {
            name: "8140 Mutualité sociale agricole",
            id: "8140 Mutualité sociale agricole",
        },
        {
            name: "8150 Régime maladie des non-salariés non agricoles",
            id: "8150 Régime maladie des non-salariés non agricoles",
        },
        {
            name: "8160 Régime vieillesse ne dépendant pas du régime général de la Sécurité Sociale",
            id: "8160 Régime vieillesse ne dépendant pas du régime général de la Sécurité Sociale",
        },
        {
            name: "8170 Régime d'assurance chômage",
            id: "8170 Régime d'assurance chômage",
        },
        {
            name: "8190 Autre régime de prévoyance sociale",
            id: "8190 Autre régime de prévoyance sociale",
        },
        { name: "8210 Mutuelle", id: "8210 Mutuelle" },
        {
            name: "8250 Assurance mutuelle agricole",
            id: "8250 Assurance mutuelle agricole",
        },
        {
            name: "8290 Autre organisme mutualiste",
            id: "8290 Autre organisme mutualiste",
        },
        {
            name: "8310 Comité social économique d'entreprise",
            id: "8310 Comité social économique d'entreprise",
        },
        {
            name: "8311 Comité social économique d'établissement",
            id: "8311 Comité social économique d'établissement",
        },
        {
            name: "8410 Syndicat de salariés",
            id: "8410 Syndicat de salariés",
        },
        { name: "8420 Syndicat patronal", id: "8420 Syndicat patronal" },
        {
            name: "8450 Ordre professionnel ou assimilé",
            id: "8450 Ordre professionnel ou assimilé",
        },
        {
            name: "8470 Centre technique industriel ou comité professionnel du développement économique",
            id: "8470 Centre technique industriel ou comité professionnel du développement économique",
        },
        {
            name: "8490 Autre organisme professionnel",
            id: "8490 Autre organisme professionnel",
        },
        {
            name: "8510 Institution de prévoyance",
            id: "8510 Institution de prévoyance",
        },
        {
            name: "8520 Institution de retraite supplémentaire",
            id: "8520 Institution de retraite supplémentaire",
        },
        {
            name: "9110 Syndicat de copropriété",
            id: "9110 Syndicat de copropriété",
        },
        {
            name: "9150 Association syndicale libre",
            id: "9150 Association syndicale libre",
        },
        {
            name: "9210 Association non déclarée",
            id: "9210 Association non déclarée",
        },
        {
            name: "9220 Association déclarée",
            id: "9220 Association déclarée",
        },
        {
            name: "9221 Association déclarée d'insertion par l'économique",
            id: "9221 Association déclarée d'insertion par l'économique",
        },
        {
            name: "9222 Association intermédiaire",
            id: "9222 Association intermédiaire",
        },
        {
            name: "9223 Groupement d'employeurs",
            id: "9223 Groupement d'employeurs",
        },
        {
            name: "9224 Association d'avocats à responsabilité professionnelle individuelle",
            id: "9224 Association d'avocats à responsabilité professionnelle individuelle",
        },
        {
            name: "9230 Association déclarée, reconnue d'utilité publique",
            id: "9230 Association déclarée, reconnue d'utilité publique",
        },
        { name: "9240 Congrégation", id: "9240 Congrégation" },
        {
            name: "9260 Association de droit local (Bas-Rhin, Haut-Rhin et Moselle)",
            id: "9260 Association de droit local (Bas-Rhin, Haut-Rhin et Moselle)",
        },
        { name: "9300 Fondation", id: "9300 Fondation" },
        {
            name: "9900 Autre personne morale de droit privé",
            id: "9900 Autre personne morale de droit privé",
        },
        {
            name: "9970 Groupement de coopération sanitaire à gestion privée",
            id: "9970 Groupement de coopération sanitaire à gestion privée",
        },
        {
            name: "E100 Société Anonyme (SAM)",
            id: "E100 Société Anonyme (SAM)",
        },
        {
            name: "E110 Employeurs de Gens de Maison",
            id: "E110 Employeurs de Gens de Maison",
        },
    ];
}

export function conventionCollectivesLst() {
    return [
        { name: "A001 Accouvage", id: "A001 Accouvage" },
        {
            name: "A002 Administrateurs et mandataires judiciaires (personnel)",
            id: "A002 Administrateurs et mandataires judiciaires (personnel)",
        },
        {
            name: "A003 Aéraulique, thermique et frigorifique",
            id: "A003 Aéraulique, thermique et frigorifique",
        },
        {
            name: "A004 Aérodromes commerciaux (aéroports : personnels des exploitants)",
            id: "A004 Aérodromes commerciaux (aéroports : personnels des exploitants)",
        },
        {
            name: "A005 Aéroports (région parisienne) (fusion avec T020 - Transport aérien : personnel au sol)",
            id: "A005 Aéroports (région parisienne) (fusion avec T020 - Transport aérien : personnel au sol)",
        },
        {
            name: "A006 Agences de mannequins (fusion avec C089 : Création et événement (entreprises techniques))",
            id: "A006 Agences de mannequins (fusion avec C089 : Création et événement (entreprises techniques))",
        },
        {
            name: "A008 Agriculture (accords nationaux)",
            id: "A008 Agriculture (accords nationaux)",
        },
        {
            name: "A009 Agriculture (centres d'initiatives pour valoriser l'agriculture et le milieu rural)",
            id: "A009 Agriculture (centres d'initiatives pour valoriser l'agriculture et le milieu rural)",
        },
        {
            name: "A013 Alimentation (gérants mandataires non salariés)",
            id: "A013 Alimentation (gérants mandataires non salariés)",
        },
        {
            name: "A014 Alimentation industries agroalimentaires (accords nationaux)",
            id: "A014 Alimentation industries agroalimentaires (accords nationaux)",
        },
        {
            name: "A015 Alimentation industries agroalimentaires de la Réunion",
            id: "A015 Alimentation industries agroalimentaires de la Réunion",
        },
        {
            name: "A017 Ameublement (fabrication)",
            id: "A017 Ameublement (fabrication)",
        },
        { name: "A018 Ameublement (négoce)", id: "A018 Ameublement (négoce)" },
        {
            name: "A019 Laboratoires de biologie médicale extra-hospitaliers (ex : Analyses médicales)",
            id: "A019 Laboratoires de biologie médicale extra-hospitaliers (ex : Analyses médicales)",
        },
        {
            name: "A020 Métiers ÉCLAT (de l'Éducation, de la Culture, des Loisirs et de l'Animation",
            id: "A020 Métiers ÉCLAT (de l'Éducation, de la Culture, des Loisirs et de l'Animation",
        },
        { name: "A021 Aquaculture", id: "A021 Aquaculture" },
        {
            name: "A022 Acteurs du développement et de l'ingénierie territoriale d'intérêt général (ADITIG) (ex : Architecture (conseils d'architecture, d'urbanisme et de l'environnement))",
            id: "A022 Acteurs du développement et de l'ingénierie territoriale d'intérêt général (ADITIG) (ex : Architecture (conseils d'architecture, d'urbanisme et de l'environnement))",
        },
        {
            name: "A023 Architecture (entreprises)",
            id: "A023 Architecture (entreprises)",
        },
        {
            name: "A024 Artistes interprètes (émissions de télévision) - (fusion avec A038 : Audiovisuel - production)",
            id: "A024 Artistes interprètes (émissions de télévision) - (fusion avec A038 : Audiovisuel - production)",
        },
        {
            name: "A026 Artistique (entreprises artistiques et culturelles)",
            id: "A026 Artistique (entreprises artistiques et culturelles)",
        },
        {
            name: "A027 Assainissement et maintenance industrielle",
            id: "A027 Assainissement et maintenance industrielle",
        },
        { name: "A028 Assistance", id: "A028 Assistance" },
        { name: "A029 Assistants maternels", id: "A029 Assistants maternels" },
        {
            name: "A030 Assurances (services extérieurs de production des sociétés - échelons intermédiaires)",
            id: "A030 Assurances (services extérieurs de production des sociétés - échelons intermédiaires)",
        },
        {
            name: "A031 Assurances (salariés commerciaux des sociétés)",
            id: "A031 Assurances (salariés commerciaux des sociétés)",
        },
        {
            name: "A032 Assurances (agences générales)",
            id: "A032 Assurances (agences générales)",
        },
        {
            name: "A033 Assurances (cadres de direction des sociétés)",
            id: "A033 Assurances (cadres de direction des sociétés)",
        },
        {
            name: "A034 Assurances (courtage)",
            id: "A034 Assurances (courtage)",
        },
        {
            name: "A035 Assurances (inspection)",
            id: "A035 Assurances (inspection)",
        },
        {
            name: "A036 Assurances (sociétés)",
            id: "A036 Assurances (sociétés)",
        },
        {
            name: "A038 Audiovisuel (production)",
            id: "A038 Audiovisuel (production)",
        },
        {
            name: "A039 Audiovisuel (production de films d'animation)",
            id: "A039 Audiovisuel (production de films d'animation)",
        },
        {
            name: "A041 Audiovisuel, cinéma, publicité, loisirs et spectacle (accords nationaux)",
            id: "A041 Audiovisuel, cinéma, publicité, loisirs et spectacle (accords nationaux)",
        },
        {
            name: "A043 Auto moto de la Réunion",
            id: "A043 Auto moto de la Réunion",
        },
        { name: "A044 Automobile", id: "A044 Automobile" },
        { name: "A045 Automobile (Guyane)", id: "A045 Automobile (Guyane)" },
        { name: "A046 Autoroutes", id: "A046 Autoroutes" },
        {
            name: "A047 Avocats au Conseil d'État et à la Cour de cassation (personnel salarié)",
            id: "A047 Avocats au Conseil d'État et à la Cour de cassation (personnel salarié)",
        },
        {
            name: "A048 Avocats (personnel salarié)",
            id: "A048 Avocats (personnel salarié)",
        },
        { name: "A049 Avocats salariés", id: "A049 Avocats salariés" },
        {
            name: "A052 Aide, accompagnement, soins et services à domicile (BAD)",
            id: "A052 Aide, accompagnement, soins et services à domicile (BAD)",
        },
        {
            name: "A053 Arboriculture fruitière Ouest de la France",
            id: "A053 Arboriculture fruitière Ouest de la France",
        },
        {
            name: "A054 Alimentation (industries alimentaires diverses : 5 branches)",
            id: "A054 Alimentation (industries alimentaires diverses : 5 branches)",
        },
        {
            name: "A055 Entreprises et associations syndicales des propriétaires exploitants de Chapiteaux (fusion avec C089 : Création et événement (entreprises techniques))",
            id: "A055 Entreprises et associations syndicales des propriétaires exploitants de Chapiteaux (fusion avec C089 : Création et événement (entreprises techniques))",
        },
        {
            name: "A056 Associations de gestion et de comptabilité (fusion avec E026 : Experts-comptables et commissaires aux comptes)",
            id: "A056 Associations de gestion et de comptabilité (fusion avec E026 : Experts-comptables et commissaires aux comptes)",
        },
        {
            name: "A057 A.D.A.S.E.A. (personnel des)",
            id: "A057 A.D.A.S.E.A. (personnel des)",
        },
        {
            name: "A058 Artistes musiciens des cultes pour la zone apostolique de Paris",
            id: "A058 Artistes musiciens des cultes pour la zone apostolique de Paris",
        },
        {
            name: "A059 Ambulances (Guyane : ouvriers, employés et techniciens)",
            id: "A059 Ambulances (Guyane : ouvriers, employés et techniciens)",
        },
        {
            name: "A060 Accord Collectif Interentreprises du Centre Spatial Guyanais - CSG",
            id: "A060 Accord Collectif Interentreprises du Centre Spatial Guyanais - CSG",
        },
        {
            name: "A061 A.S.A.V.P.A. et F.R.A.S.A.V.P.A. (personnel des)",
            id: "A061 A.S.A.V.P.A. et F.R.A.S.A.V.P.A. (personnel des)",
        },
        {
            name: "A062 Accord professionnel des Collaborateurs de députés",
            id: "A062 Accord professionnel des Collaborateurs de députés",
        },
        {
            name: "A063 Agences de recherches privées (Personnel salarié)",
            id: "A063 Agences de recherches privées (Personnel salarié)",
        },
        {
            name: "A064 Automobile, réparation, commerce et activités annexes de la Polynésie Française",
            id: "A064 Automobile, réparation, commerce et activités annexes de la Polynésie Française",
        },
        {
            name: "A065 Assurances de la Polynésie Française",
            id: "A065 Assurances de la Polynésie Française",
        },
        { name: "B001 Banques", id: "B001 Banques" },
        {
            name: "B002 Banques (Guadeloupe, Saint Martin et Saint Barthélemy)",
            id: "B002 Banques (Guadeloupe, Saint Martin et Saint Barthélemy)",
        },
        { name: "B003 Banques (Guyane)", id: "B003 Banques (Guyane)" },
        { name: "B004 Banques (Martinique)", id: "B004 Banques (Martinique)" },
        {
            name: "B005 Bâtiment (accords nationaux)",
            id: "B005 Bâtiment (accords nationaux)",
        },
        { name: "B006 Bâtiment (Cadres)", id: "B006 Bâtiment (Cadres)" },
        {
            name: "B007 Bâtiment (Cadres : Région parisienne)",
            id: "B007 Bâtiment (Cadres : Région parisienne)",
        },
        { name: "B008 Bâtiment (ETAM)", id: "B008 Bâtiment (ETAM)" },
        {
            name: "B009 Bâtiment (ETAM : Région parisienne)",
            id: "B009 Bâtiment (ETAM : Région parisienne)",
        },
        {
            name: "B010 Bâtiment (Ouvriers : Alsace)",
            id: "B010 Bâtiment (Ouvriers : Alsace)",
        },
        {
            name: "B011 Bâtiment (Ouvriers : Aquitaine +10 salariés)",
            id: "B011 Bâtiment (Ouvriers : Aquitaine +10 salariés)",
        },
        {
            name: "B012 Bâtiment (Ouvriers : Aquitaine -10 salariés)",
            id: "B012 Bâtiment (Ouvriers : Aquitaine -10 salariés)",
        },
        {
            name: "B013 Bâtiment (Ouvriers : Bretagne)",
            id: "B013 Bâtiment (Ouvriers : Bretagne)",
        },
        {
            name: "B014 Bâtiment (Ouvriers : Champagne-Ardennes + 10 salariés)",
            id: "B014 Bâtiment (Ouvriers : Champagne-Ardennes + 10 salariés)",
        },
        {
            name: "B015 Bâtiment (Ouvriers : Champagne-Ardennes - 10 salariés)",
            id: "B015 Bâtiment (Ouvriers : Champagne-Ardennes - 10 salariés)",
        },
        {
            name: "B016 Bâtiment (Ouvriers : Cher +10 salariés)",
            id: "B016 Bâtiment (Ouvriers : Cher +10 salariés)",
        },
        {
            name: "B017 Bâtiment (Ouvriers : Cher - 10 salariés)",
            id: "B017 Bâtiment (Ouvriers : Cher - 10 salariés)",
        },
        {
            name: "B018 Bâtiment (Ouvriers : Corse)",
            id: "B018 Bâtiment (Ouvriers : Corse)",
        },
        {
            name: "B019 Bâtiment (Ouvriers : Drôme-Ardèche)",
            id: "B019 Bâtiment (Ouvriers : Drôme-Ardèche)",
        },
        {
            name: "B020 Bâtiment (Ouvriers : Isère +10 salariés)",
            id: "B020 Bâtiment (Ouvriers : Isère +10 salariés)",
        },
        {
            name: "B021 Bâtiment (Ouvriers : Isère - 10 salariés)",
            id: "B021 Bâtiment (Ouvriers : Isère - 10 salariés)",
        },
        {
            name: "B022 Bâtiment (Ouvriers : Languedoc-Roussillon - 10 salariés)",
            id: "B022 Bâtiment (Ouvriers : Languedoc-Roussillon - 10 salariés)",
        },
        {
            name: "B023 Bâtiment (Ouvriers : Limousin)",
            id: "B023 Bâtiment (Ouvriers : Limousin)",
        },
        {
            name: "B024 Bâtiment (Ouvriers : Loire)",
            id: "B024 Bâtiment (Ouvriers : Loire)",
        },
        {
            name: "B025 Bâtiment (Ouvriers : Nationale + 10 salariés)",
            id: "B025 Bâtiment (Ouvriers : Nationale + 10 salariés)",
        },
        {
            name: "B026 Bâtiment (Ouvriers : Nationale - 10 salariés)",
            id: "B026 Bâtiment (Ouvriers : Nationale - 10 salariés)",
        },
        {
            name: "B027 Bâtiment (Ouvriers : Basse Normandie)",
            id: "B027 Bâtiment (Ouvriers : Basse Normandie)",
        },
        {
            name: "B028 Bâtiment (Ouvriers : Haute Normandie)",
            id: "B028 Bâtiment (Ouvriers : Haute Normandie)",
        },
        {
            name: "B029 Bâtiment (Ouvriers : Pays de la Loire)",
            id: "B029 Bâtiment (Ouvriers : Pays de la Loire)",
        },
        {
            name: "B030 Bâtiment (Ouvriers : Provence-Alpes-Côte d'Azur + 10 salariés)",
            id: "B030 Bâtiment (Ouvriers : Provence-Alpes-Côte d'Azur + 10 salariés)",
        },
        {
            name: "B031 Bâtiment (Ouvriers : Provence-Alpes-Côte d'Azur - 10 salariés)",
            id: "B031 Bâtiment (Ouvriers : Provence-Alpes-Côte d'Azur - 10 salariés)",
        },
        {
            name: "B032 Bâtiment (Ouvriers : Région parisienne + 10 salariés)",
            id: "B032 Bâtiment (Ouvriers : Région parisienne + 10 salariés)",
        },
        {
            name: "B033 Bâtiment (Ouvriers : Région parisienne - 10 salariés)",
            id: "B033 Bâtiment (Ouvriers : Région parisienne - 10 salariés)",
        },
        {
            name: "B034 Batiment (Ouvriers : Savoie)",
            id: "B034 Batiment (Ouvriers : Savoie)",
        },
        {
            name: "B035 Bâtiment (Ouvriers : Tarn + 10 salariés)",
            id: "B035 Bâtiment (Ouvriers : Tarn + 10 salariés)",
        },
        {
            name: "B036 Bâtiment (Ouvriers : Tarn - 10 salariés)",
            id: "B036 Bâtiment (Ouvriers : Tarn - 10 salariés)",
        },
        {
            name: "B037 Bâtiment et travaux publics (Guadeloupe : ouvriers)",
            id: "B037 Bâtiment et travaux publics (Guadeloupe : ouvriers)",
        },
        {
            name: "B038 Bâtiment et Travaux publics (Guyane : ouvriers)",
            id: "B038 Bâtiment et Travaux publics (Guyane : ouvriers)",
        },
        {
            name: "B039 Bâtiment et Travaux publics (La Réunion : cadres)",
            id: "B039 Bâtiment et Travaux publics (La Réunion : cadres)",
        },
        {
            name: "B040 Bâtiment et Travaux publics (La Réunion : ETAM)",
            id: "B040 Bâtiment et Travaux publics (La Réunion : ETAM)",
        },
        {
            name: "B041 Bâtiment et Travaux publics (La Réunion : ouvriers)",
            id: "B041 Bâtiment et Travaux publics (La Réunion : ouvriers)",
        },
        {
            name: "B042 Bâtiment et Travaux publics (Martinique : ouvriers)",
            id: "B042 Bâtiment et Travaux publics (Martinique : ouvriers)",
        },
        {
            name: "B043 Bâtiment et Travaux publics (Saint-Pierre-et-Miquelon)",
            id: "B043 Bâtiment et Travaux publics (Saint-Pierre-et-Miquelon)",
        },
        { name: "B044 Bétail et viandes", id: "B044 Bétail et viandes" },
        {
            name: "B045 Bijouterie, joaillerie, orfèvrerie",
            id: "B045 Bijouterie, joaillerie, orfèvrerie",
        },
        {
            name: "B047 Blanchisserie, teinturerie, nettoyage (accords nationaux)",
            id: "B047 Blanchisserie, teinturerie, nettoyage (accords nationaux)",
        },
        {
            name: "B049 Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",
            id: "B049 Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",
        },
        {
            name: "B050 Bois (panneaux à base de bois)",
            id: "B050 Bois (panneaux à base de bois)",
        },
        {
            name: "B051 Bois d'oeuvre et produits dérivés (Fusion avec CCN M107 : Matériaux de construction (négoce))",
            id: "B051 Bois d'oeuvre et produits dérivés (Fusion avec CCN M107 : Matériaux de construction (négoce))",
        },
        {
            name: "B052 Bois de pin maritime en forêt de Gascogne (fusion avec B053 - Bois et scieries : travail mécanique, négoce et importation)",
            id: "B052 Bois de pin maritime en forêt de Gascogne (fusion avec B053 - Bois et scieries : travail mécanique, négoce et importation)",
        },
        {
            name: "B053 Bois et scieries (travail mécanique, négoce et importation)",
            id: "B053 Bois et scieries (travail mécanique, négoce et importation)",
        },
        {
            name: "B054 Boissons (distributeurs conseils hors domicile)",
            id: "B054 Boissons (distributeurs conseils hors domicile)",
        },
        {
            name: "B055 Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet (ex Bonneterie, lingerie, confection, mercerie)",
            id: "B055 Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet (ex Bonneterie, lingerie, confection, mercerie)",
        },
        {
            name: "B056 Boucherie, boucherie charcuterie, boucherie hippophagique",
            id: "B056 Boucherie, boucherie charcuterie, boucherie hippophagique",
        },
        {
            name: "B057 Boulangerie-pâtisserie (entreprises artisanales)",
            id: "B057 Boulangerie-pâtisserie (entreprises artisanales)",
        },
        {
            name: "B058 Boulangerie-pâtisserie (Guyane)",
            id: "B058 Boulangerie-pâtisserie (Guyane)",
        },
        {
            name: "B060 Boulangerie-pâtisserie (Martinique)",
            id: "B060 Boulangerie-pâtisserie (Martinique)",
        },
        {
            name: "B061 Boulangerie-pâtisserie (activités industrielles)",
            id: "B061 Boulangerie-pâtisserie (activités industrielles)",
        },
        {
            name: "B063 Boyauderie (fusion avec C025 - Charcuterie : industries)",
            id: "B063 Boyauderie (fusion avec C025 - Charcuterie : industries)",
        },
        { name: "B064 Bricolage", id: "B064 Bricolage" },
        {
            name: "B065 Bureaux d'études techniques",
            id: "B065 Bureaux d'études techniques",
        },
        {
            name: "B066 Bâtiment et Travaux publics (Martinique : ETAM)",
            id: "B066 Bâtiment et Travaux publics (Martinique : ETAM)",
        },
        {
            name: "B067 Bâtiment et Travaux publics (Guyane : ETAM)",
            id: "B067 Bâtiment et Travaux publics (Guyane : ETAM)",
        },
        {
            name: "B068 Bâtiment et Travaux publics (Guyane : Ingénieurs et Cadres)",
            id: "B068 Bâtiment et Travaux publics (Guyane : Ingénieurs et Cadres)",
        },
        { name: "B069 Banque populaire", id: "B069 Banque populaire" },
        { name: "B070 Basket professionnel", id: "B070 Basket professionnel" },
        {
            name: "B071 Bâtiment et Travaux publics (Mayotte : Ouvriers)",
            id: "B071 Bâtiment et Travaux publics (Mayotte : Ouvriers)",
        },
        {
            name: "B072 Bâtiment et travaux publics (Guadeloupe : ETAM)",
            id: "B072 Bâtiment et travaux publics (Guadeloupe : ETAM)",
        },
        {
            name: "B073 Convention collective monégasque du travail du personnel des banques",
            id: "B073 Convention collective monégasque du travail du personnel des banques",
        },
        {
            name: "B074 Banque de France (statut du personnel)",
            id: "B074 Banque de France (statut du personnel)",
        },
        {
            name: "B075 Bâtiment et Travaux publics (Mayotte : Ingénieurs - Assimilés et Cadres)",
            id: "B075 Bâtiment et Travaux publics (Mayotte : Ingénieurs - Assimilés et Cadres)",
        },
        {
            name: "B076 Convention collective monégasque du Bâtiment",
            id: "B076 Convention collective monégasque du Bâtiment",
        },
        {
            name: "B077 Bâtiment et Travaux publics (Mayotte : ETAM)",
            id: "B077 Bâtiment et Travaux publics (Mayotte : ETAM)",
        },
        {
            name: "B078 Personnel des Banques et des Sociétés financières de la Polynésie Française",
            id: "B078 Personnel des Banques et des Sociétés financières de la Polynésie Française",
        },
        {
            name: "B079 Bâtiment et Travaux Publics de Polynésie Française",
            id: "B079 Bâtiment et Travaux Publics de Polynésie Française",
        },
        { name: "C001 Cabinets médicaux", id: "C001 Cabinets médicaux" },
        { name: "C002 Cafétérias", id: "C002 Cafétérias" },
        {
            name: "C003 Camping (industries) (fusion avec S009 : Sport - Commerce des articles de sport et équipements de loisirs)",
            id: "C003 Camping (industries) (fusion avec S009 : Sport - Commerce des articles de sport et équipements de loisirs)",
        },
        {
            name: "C004 Cancer (centres de lutte)",
            id: "C004 Cancer (centres de lutte)",
        },
        { name: "C005 Caoutchouc", id: "C005 Caoutchouc" },
        {
            name: "C006 Carrières et matériaux (accords nationaux)",
            id: "C006 Carrières et matériaux (accords nationaux)",
        },
        {
            name: "C007 Carrières et matériaux (cadres et assimilés)",
            id: "C007 Carrières et matériaux (cadres et assimilés)",
        },
        {
            name: "C008 Carrières et matériaux (Employés, Techniciens et Agents de Maîtrise)",
            id: "C008 Carrières et matériaux (Employés, Techniciens et Agents de Maîtrise)",
        },
        {
            name: "C009 Carrières et matériaux (ouvriers)",
            id: "C009 Carrières et matériaux (ouvriers)",
        },
        {
            name: "C010 Cartonnage (industries)",
            id: "C010 Cartonnage (industries)",
        },
        { name: "C011 Casinos", id: "C011 Casinos" },
        {
            name: "C012 Centres d'hébergement et de réadaptation sociale ( Fusion avec H007 : Handicapés (établissements et services pour personnes inadaptées et handicapées))",
            id: "C012 Centres d'hébergement et de réadaptation sociale ( Fusion avec H007 : Handicapés (établissements et services pour personnes inadaptées et handicapées))",
        },
        {
            name: "C013 Centres d'entrainement (chevaux de courses au galop)",
            id: "C013 Centres d'entrainement (chevaux de courses au galop)",
        },
        {
            name: "C014 Centres d'entrainement (chevaux de courses au trot)",
            id: "C014 Centres d'entrainement (chevaux de courses au trot)",
        },
        {
            name: "C017 Centres de gestion agréés et habilités agricoles",
            id: "C017 Centres de gestion agréés et habilités agricoles",
        },
        { name: "C018 Centres équestres", id: "C018 Centres équestres" },
        {
            name: "C019 Céramique d'art (fusion avec C020 : Céramiques de France (industries))",
            id: "C019 Céramique d'art (fusion avec C020 : Céramiques de France (industries))",
        },
        {
            name: "C020 Céramiques de France (industries)",
            id: "C020 Céramiques de France (industries)",
        },
        {
            name: "C021 Céréales, meunerie, approvisionnement, alimentation (bétail), oléagineux",
            id: "C021 Céréales, meunerie, approvisionnement, alimentation (bétail), oléagineux",
        },
        { name: "C022 Chaînes thématiques", id: "C022 Chaînes thématiques" },
        { name: "C023 Champagne", id: "C023 Champagne" },
        {
            name: "C025 Charcuterie (industries)",
            id: "C025 Charcuterie (industries)",
        },
        {
            name: "C026 Charcuterie de détail",
            id: "C026 Charcuterie de détail",
        },
        {
            name: "C027 Chasse (personnels des structures associatives cynégétiques)",
            id: "C027 Chasse (personnels des structures associatives cynégétiques)",
        },
        {
            name: "C028 Chaussure et articles chaussants (industrie)",
            id: "C028 Chaussure et articles chaussants (industrie)",
        },
        {
            name: "C029 Chaussure (commerce succursaliste)",
            id: "C029 Chaussure (commerce succursaliste)",
        },
        {
            name: "C030 Chaussures (détaillants)",
            id: "C030 Chaussures (détaillants)",
        },
        {
            name: "C034 Chemiserie sur mesure (fusion avec C084 : Couture parisienne)",
            id: "C034 Chemiserie sur mesure (fusion avec C084 : Couture parisienne)",
        },
        { name: "C035 Chimie (industries)", id: "C035 Chimie (industries)" },
        {
            name: "C036 Ciments (industries : accords nationaux)",
            id: "C036 Ciments (industries : accords nationaux)",
        },
        {
            name: "C037 Ciments (industries : cadres)",
            id: "C037 Ciments (industries : cadres)",
        },
        {
            name: "C038 Ciments (industries : employés, techniciens, dessinateurs et agents de maîtrise)",
            id: "C038 Ciments (industries : employés, techniciens, dessinateurs et agents de maîtrise)",
        },
        {
            name: "C039 Ciments (industries : ouvriers)",
            id: "C039 Ciments (industries : ouvriers)",
        },
        {
            name: "C040 Cinéma (exploitation) ",
            id: "C040 Cinéma (exploitation) ",
        },
        {
            name: "C041 Cinéma (exploitation : services généraux et administratifs - cadres) (fusion avec C040 : Cinéma exploitation)",
            id: "C041 Cinéma (exploitation : services généraux et administratifs - cadres) (fusion avec C040 : Cinéma exploitation)",
        },
        {
            name: "C042 Cinéma (exploitation : services généraux et administratifs - employés, techniciens, ouvriers) (fusion avec C040 : Cinéma exploitation)",
            id: "C042 Cinéma (exploitation : services généraux et administratifs - employés, techniciens, ouvriers) (fusion avec C040 : Cinéma exploitation)",
        },
        {
            name: "C048 Cinéma (distribution des films : cadres et agents de maîtrise)",
            id: "C048 Cinéma (distribution des films : cadres et agents de maîtrise)",
        },
        {
            name: "C049 Cinéma (distribution des films : employés et ouvriers)",
            id: "C049 Cinéma (distribution des films : employés et ouvriers)",
        },
        { name: "C050 Coiffure", id: "C050 Coiffure" },
        {
            name: "C051 Combustibles (négoce et distribution)",
            id: "C051 Combustibles (négoce et distribution)",
        },
        {
            name: "C052 Commerces de détail alimentaires et non alimentaires : Cambrai",
            id: "C052 Commerces de détail alimentaires et non alimentaires : Cambrai",
        },
        {
            name: "C053 Commerces de détail alimentaires et non alimentaires : Loiret",
            id: "C053 Commerces de détail alimentaires et non alimentaires : Loiret",
        },
        {
            name: "C054 Commerces de détail alimentaires et non alimentaires : Vosges",
            id: "C054 Commerces de détail alimentaires et non alimentaires : Vosges",
        },
        {
            name: "C055 Commerces de détail non alimentaires (Convention collective nationale)",
            id: "C055 Commerces de détail non alimentaires (Convention collective nationale)",
        },
        {
            name: "C056 Commerces de détail non alimentaires (Ain)",
            id: "C056 Commerces de détail non alimentaires (Ain)",
        },
        {
            name: "C057 Commerces de détail non alimentaires (Ardennes)",
            id: "C057 Commerces de détail non alimentaires (Ardennes)",
        },
        {
            name: "C058 Commerces de détail non alimentaires (Finistère)",
            id: "C058 Commerces de détail non alimentaires (Finistère)",
        },
        {
            name: "C059 Commerces de détail non alimentaires (Gironde)",
            id: "C059 Commerces de détail non alimentaires (Gironde)",
        },
        {
            name: "C060 Commerces de détail non alimentaires (Loire)",
            id: "C060 Commerces de détail non alimentaires (Loire)",
        },
        {
            name: "C061 Commerces de détail non alimentaires (Lyon)",
            id: "C061 Commerces de détail non alimentaires (Lyon)",
        },
        {
            name: "C062 Commerces de détail non alimentaires (Maine-et-Loire)",
            id: "C062 Commerces de détail non alimentaires (Maine-et-Loire)",
        },
        {
            name: "C063 Commerces de détail non alimentaires (Meurthe-et-Moselle)",
            id: "C063 Commerces de détail non alimentaires (Meurthe-et-Moselle)",
        },
        {
            name: "C064 Commerces de détail non alimentaires (Morbihan)",
            id: "C064 Commerces de détail non alimentaires (Morbihan)",
        },
        {
            name: "C065 Commerces de détail non alimentaires (Hautes-Pyrénées)",
            id: "C065 Commerces de détail non alimentaires (Hautes-Pyrénées)",
        },
        {
            name: "C066 Commerces de détail non alimentaires (Rennes)",
            id: "C066 Commerces de détail non alimentaires (Rennes)",
        },
        {
            name: "C067 Commerces de détail non alimentaires (Bas-Rhin et Haut-Rhin)",
            id: "C067 Commerces de détail non alimentaires (Bas-Rhin et Haut-Rhin)",
        },
        {
            name: "C068 Commerces de détail non alimentaires (Tarn)",
            id: "C068 Commerces de détail non alimentaires (Tarn)",
        },
        { name: "C069 Commerces de gros", id: "C069 Commerces de gros" },
        {
            name: "C070 Commerces de détail et de gros à prédominance alimentaire",
            id: "C070 Commerces de détail et de gros à prédominance alimentaire",
        },
        {
            name: "C071 Commerces de gros et de détail alimentaires et non alimentaires (Martinique)",
            id: "C071 Commerces de gros et de détail alimentaires et non alimentaires (Martinique)",
        },
        {
            name: "C074 Commissaires-priseurs judiciaires et sociétés de ventes volontaires",
            id: "C074 Commissaires-priseurs judiciaires et sociétés de ventes volontaires",
        },
        { name: "C076 Conchyliculture", id: "C076 Conchyliculture" },
        {
            name: "C078 Confiserie, chocolaterie, biscuiterie (détaillants, détaillants fabricants)",
            id: "C078 Confiserie, chocolaterie, biscuiterie (détaillants, détaillants fabricants)",
        },
        {
            name: "C079 Conserveries (Coopératives et SICA)",
            id: "C079 Conserveries (Coopératives et SICA)",
        },
        {
            name: "C080 Coopération maritime (fusion avec C076 : Conchyliculture)",
            id: "C080 Coopération maritime (fusion avec C076 : Conchyliculture)",
        },
        {
            name: "C081 Coopératives de consommation (gérants non salariés)",
            id: "C081 Coopératives de consommation (gérants non salariés)",
        },
        {
            name: "C082 Coopératives de consommation (personnel) (ex : Coopératives de consommation : salariés)",
            id: "C082 Coopératives de consommation (personnel) (ex : Coopératives de consommation : salariés)",
        },
        {
            name: "C083 Cordonnerie multiservice (fusion avec M009 : Maroquinerie industries)",
            id: "C083 Cordonnerie multiservice (fusion avec M009 : Maroquinerie industries)",
        },
        { name: "C084 Couture parisienne", id: "C084 Couture parisienne" },
        { name: "C085 Crédit agricole", id: "C085 Crédit agricole" },
        {
            name: "C087 Cuirs et peaux (industrie) (fusion avec M009 : Maroquinerie industries)",
            id: "C087 Cuirs et peaux (industrie) (fusion avec M009 : Maroquinerie industries)",
        },
        {
            name: "C089 Création et événement (entreprises techniques)",
            id: "C089 Création et événement (entreprises techniques)",
        },
        {
            name: "C090 Confédération paysanne",
            id: "C090 Confédération paysanne",
        },
        {
            name: "C091 Coopératives fruitières de l'Ain, du Doubs et du Jura",
            id: "C091 Coopératives fruitières de l'Ain, du Doubs et du Jura",
        },
        {
            name: "C092 Champignonnières de la région Centre",
            id: "C092 Champignonnières de la région Centre",
        },
        {
            name: "C093 Accord national des Caisses d'Epargne ",
            id: "C093 Accord national des Caisses d'Epargne ",
        },
        {
            name: "C094 Production cinématographique",
            id: "C094 Production cinématographique",
        },
        { name: "C095 Personnel de la CFTC", id: "C095 Personnel de la CFTC" },
        {
            name: "C097 Commerce de La Réunion",
            id: "C097 Commerce de La Réunion",
        },
        { name: "C098 Réseau CERFRANCE", id: "C098 Réseau CERFRANCE" },
        {
            name: "C099 Chambre d'Agriculture du Gers (personnel non régi par le statut administratif)",
            id: "C099 Chambre d'Agriculture du Gers (personnel non régi par le statut administratif)",
        },
        {
            name: "C100 Chambre d'Agriculture de l'Ariège (personnel non régi par le statut administratif)",
            id: "C100 Chambre d'Agriculture de l'Ariège (personnel non régi par le statut administratif)",
        },
        {
            name: "C101 Statut des personnels des organismes de développement économique (CNER-UCCAR)",
            id: "C101 Statut des personnels des organismes de développement économique (CNER-UCCAR)",
        },
        {
            name: "C102 Chambres de commerce et d'industrie (statut du personnel administratif)",
            id: "C102 Chambres de commerce et d'industrie (statut du personnel administratif)",
        },
        {
            name: "C103 Chambre d'Agriculture de l'Aveyron (personnel non régi par le statut administratif)",
            id: "C103 Chambre d'Agriculture de l'Aveyron (personnel non régi par le statut administratif)",
        },
        {
            name: "C104 Statut du personnel administratif des Chambres d'Agriculture",
            id: "C104 Statut du personnel administratif des Chambres d'Agriculture",
        },
        {
            name: "C105 Statut du chargé de mission (service d'animation de l'enseignement catholique)",
            id: "C105 Statut du chargé de mission (service d'animation de l'enseignement catholique)",
        },
        {
            name: "C107 Cabinets médicaux de la Martinique",
            id: "C107 Cabinets médicaux de la Martinique",
        },
        {
            name: "C108 Centres d'Initiatives pour Valoriser l'Agriculture et le Milieu rural (CIVAM)",
            id: "C108 Centres d'Initiatives pour Valoriser l'Agriculture et le Milieu rural (CIVAM)",
        },
        {
            name: "C109 Chambre d'Agriculture de la région Centre (personnel non régi par le statut administratif)",
            id: "C109 Chambre d'Agriculture de la région Centre (personnel non régi par le statut administratif)",
        },
        {
            name: "C110 Chambre d'Agriculture de la Manche (personnel non régi par le statut administratif)",
            id: "C110 Chambre d'Agriculture de la Manche (personnel non régi par le statut administratif)",
        },
        {
            name: "C111 Statut du chef d'établissement de l'enseignement catholique",
            id: "C111 Statut du chef d'établissement de l'enseignement catholique",
        },
        {
            name: "C112 Chambre d'Agriculture de la Sarthe (personnel non régi par le statut administratif)",
            id: "C112 Chambre d'Agriculture de la Sarthe (personnel non régi par le statut administratif)",
        },
        {
            name: "C113 Comité national des pêches maritimes et des élevages marins",
            id: "C113 Comité national des pêches maritimes et des élevages marins",
        },
        {
            name: "C114 Accord national des Cadres Dirigeants de la Coopération Agricole",
            id: "C114 Accord national des Cadres Dirigeants de la Coopération Agricole",
        },
        {
            name: "C115 Chambre d'Agriculture de la Drôme (personnel non régi par le statut administratif)",
            id: "C115 Chambre d'Agriculture de la Drôme (personnel non régi par le statut administratif)",
        },
        {
            name: "C116 Statut du personnel de l'Unité Economique et Sociale de la CFDT de Bretagne",
            id: "C116 Statut du personnel de l'Unité Economique et Sociale de la CFDT de Bretagne",
        },
        {
            name: "C117 Chambre d'Agriculture de l'Isère (personnel)",
            id: "C117 Chambre d'Agriculture de l'Isère (personnel)",
        },
        {
            name: "C118 Commerce de La Guadeloupe",
            id: "C118 Commerce de La Guadeloupe",
        },
        {
            name: "C119 Chambre d'Agriculture de la Haute-Vienne (personnel non régi par le statut administratif)",
            id: "C119 Chambre d'Agriculture de la Haute-Vienne (personnel non régi par le statut administratif)",
        },
        {
            name: "C120 Commissionnaires en douane et agents auxiliaires (Martinique)",
            id: "C120 Commissionnaires en douane et agents auxiliaires (Martinique)",
        },
        {
            name: "C121 Chambre d'Agriculture de Seine-et-Marne (personnel non régi par le statut administratif)",
            id: "C121 Chambre d'Agriculture de Seine-et-Marne (personnel non régi par le statut administratif)",
        },
        {
            name: "C122 Chambre d'Agriculture de l'Hérault (personnel non régi par le statut administratif)",
            id: "C122 Chambre d'Agriculture de l'Hérault (personnel non régi par le statut administratif)",
        },
        {
            name: "C123 C.R.E.D.O.C. (personnel du)",
            id: "C123 C.R.E.D.O.C. (personnel du)",
        },
        {
            name: "C124 Chaux (industries) - (fusion avec la branche : Carrières et matériaux (industries))",
            id: "C124 Chaux (industries) - (fusion avec la branche : Carrières et matériaux (industries))",
        },
        {
            name: "C125 Chambre d'Agriculture du Lot et Garonne (personnel)",
            id: "C125 Chambre d'Agriculture du Lot et Garonne (personnel)",
        },
        {
            name: "C126 Chambre d'Agriculture du Lot (personnel non régi par le statut administratif)",
            id: "C126 Chambre d'Agriculture du Lot (personnel non régi par le statut administratif)",
        },
        {
            name: "C127 Chambre d'Agriculture de Dordogne (personnel non régi par le statut administratif)",
            id: "C127 Chambre d'Agriculture de Dordogne (personnel non régi par le statut administratif)",
        },
        {
            name: "C128 Branche Commerce & Divers de Nouvelle-Calédonie",
            id: "C128 Branche Commerce & Divers de Nouvelle-Calédonie",
        },
        {
            name: "C129 Consignataires de navire et manutentionnaires de la Martinique",
            id: "C129 Consignataires de navire et manutentionnaires de la Martinique",
        },
        {
            name: "C130 Commerce en Polynésie Française",
            id: "C130 Commerce en Polynésie Française",
        },
        {
            name: "C131 Culture de la canne à sucre de la Martinique",
            id: "C131 Culture de la canne à sucre de la Martinique",
        },
        {
            name: "C132 Champignonnières de Maine-et-Loire",
            id: "C132 Champignonnières de Maine-et-Loire",
        },
        { name: "C133 Ciments (industrie)", id: "C133 Ciments (industrie)" },
        { name: "D001 Déchets", id: "D001 Déchets" },
        { name: "D002 Dentaires (cabinets)", id: "D002 Dentaires (cabinets)" },
        {
            name: "D003 Dentaires (négoce en fournitures) - (fusion avec C069 : Commerces de gros)",
            id: "D003 Dentaires (négoce en fournitures) - (fusion avec C069 : Commerces de gros)",
        },
        {
            name: "D004 Dentaires (prothésistes et laboratoires de prothèse dentaire)",
            id: "D004 Dentaires (prothésistes et laboratoires de prothèse dentaire)",
        },
        {
            name: "D005 Désinfection, désinsectisation et dératisation",
            id: "D005 Désinfection, désinsectisation et dératisation",
        },
        {
            name: "D006 Développement économique (organismes)",
            id: "D006 Développement économique (organismes)",
        },
        {
            name: "D007 Distilleries viticoles (coopératives et union) et distillation (SICA)",
            id: "D007 Distilleries viticoles (coopératives et union) et distillation (SICA)",
        },
        { name: "D008 Distribution directe", id: "D008 Distribution directe" },
        {
            name: "D009 Diagnostic technique du bâti",
            id: "D009 Diagnostic technique du bâti",
        },
        {
            name: "D010 Personnel laïc du Diocèse de Paris",
            id: "D010 Personnel laïc du Diocèse de Paris",
        },
        {
            name: "D011 Personnel laïc du Diocèse de Sèez",
            id: "D011 Personnel laïc du Diocèse de Sèez",
        },
        {
            name: "D012 Personnel laïc de l'Association Diocésaine d'Orléans (ADO)",
            id: "D012 Personnel laïc de l'Association Diocésaine d'Orléans (ADO)",
        },
        {
            name: "D013 Personnel laïc du Diocèse de Bayeux et Lisieux",
            id: "D013 Personnel laïc du Diocèse de Bayeux et Lisieux",
        },
        {
            name: "D014 Personnel laïc du Diocèse d'Autun, Chalon et Mâcon",
            id: "D014 Personnel laïc du Diocèse d'Autun, Chalon et Mâcon",
        },
        {
            name: "D015 Personnel laïc du Diocèse de Montauban",
            id: "D015 Personnel laïc du Diocèse de Montauban",
        },
        {
            name: "D016 Personnel laïc de la congrégation des Soeurs Missionnaires de l'Evangile",
            id: "D016 Personnel laïc de la congrégation des Soeurs Missionnaires de l'Evangile",
        },
        {
            name: "D017 Personnel laïc du Diocèse de Montpellier",
            id: "D017 Personnel laïc du Diocèse de Montpellier",
        },
        {
            name: "D018 Personnel laïc du Diocèse de Clermont",
            id: "D018 Personnel laïc du Diocèse de Clermont",
        },
        {
            name: "D019 Personnel laïc du Diocèse de Nanterre",
            id: "D019 Personnel laïc du Diocèse de Nanterre",
        },
        {
            name: "D020 Personnel laïc du Diocèse de Créteil",
            id: "D020 Personnel laïc du Diocèse de Créteil",
        },
        {
            name: "D021 Personnel laïc du Diocèse de Périgueux et de Sarlat",
            id: "D021 Personnel laïc du Diocèse de Périgueux et de Sarlat",
        },
        {
            name: "D022 Personnel laïc du Diocèse de Limoges",
            id: "D022 Personnel laïc du Diocèse de Limoges",
        },
        {
            name: "D023 Personnel laïc du Diocèse de Bayonne",
            id: "D023 Personnel laïc du Diocèse de Bayonne",
        },
        {
            name: "E001 Eau (services et assainissement)",
            id: "E001 Eau (services et assainissement)",
        },
        {
            name: "E002 Eaux embouteillées, boissons sans alcool, bières (production)",
            id: "E002 Eaux embouteillées, boissons sans alcool, bières (production)",
        },
        { name: "E003 Édition", id: "E003 Édition" },
        {
            name: "E004 Édition phonographique (fusion avec E003 : Édition)",
            id: "E004 Édition phonographique (fusion avec E003 : Édition)",
        },
        {
            name: "E005 Électronique, audiovisuel et équipement ménager (commerces et services)",
            id: "E005 Électronique, audiovisuel et équipement ménager (commerces et services)",
        },
        {
            name: "E006 Enseignement privé (accords de branche du secteur)",
            id: "E006 Enseignement privé (accords de branche du secteur)",
        },
        {
            name: "E009 Enseignement privé (agricole : personnel relevant du Groupement des Organismes de Formation et de Promotion Agricoles - GOFPA)",
            id: "E009 Enseignement privé (agricole : personnel relevant du Groupement des Organismes de Formation et de Promotion Agricoles - GOFPA)",
        },
        {
            name: "E011 Enseignement privé (directeurs : primaire catholique)",
            id: "E011 Enseignement privé (directeurs : primaire catholique)",
        },
        {
            name: "E012 Enseignement privé (formateurs des CFA et des CFC)",
            id: "E012 Enseignement privé (formateurs des CFA et des CFC)",
        },
        {
            name: "E013 Enseignement privé (maîtres : primaire catholique)",
            id: "E013 Enseignement privé (maîtres : primaire catholique)",
        },
        {
            name: "E014 Enseignement privé (maîtres : primaire)",
            id: "E014 Enseignement privé (maîtres : primaire)",
        },
        {
            name: "E015 Enseignement privé (salariés des établissements privés)",
            id: "E015 Enseignement privé (salariés des établissements privés)",
        },
        {
            name: "E016 Enseignement privé (professeurs du secondaire)",
            id: "E016 Enseignement privé (professeurs du secondaire)",
        },
        {
            name: "E017 Enseignement privé (psychologues)",
            id: "E017 Enseignement privé (psychologues)",
        },
        {
            name: "E019 Enseignement privé indépendant (ex : Enseignement privé hors contrat)",
            id: "E019 Enseignement privé indépendant (ex : Enseignement privé hors contrat)",
        },
        {
            name: "E020 Enseignement privé technique hors contrat",
            id: "E020 Enseignement privé technique hors contrat",
        },
        {
            name: "E021 Enseignement supérieur privé",
            id: "E021 Enseignement supérieur privé",
        },
        {
            name: "E022 Enseignement, formation (musique et danse) : organismes associatifs (Rhône-Alpes)",
            id: "E022 Enseignement, formation (musique et danse) : organismes associatifs (Rhône-Alpes)",
        },
        {
            name: "E023 Espaces de loisirs, d'attractions et culturels",
            id: "E023 Espaces de loisirs, d'attractions et culturels",
        },
        {
            name: "E024 Expertises (évaluations industrielles et commerciales)",
            id: "E024 Expertises (évaluations industrielles et commerciales)",
        },
        {
            name: "E025 Expertises en automobile",
            id: "E025 Expertises en automobile",
        },
        {
            name: "E026 Experts-comptables et commissaires aux comptes",
            id: "E026 Experts-comptables et commissaires aux comptes",
        },
        {
            name: "E027 Exploitations frigorifiques",
            id: "E027 Exploitations frigorifiques",
        },
        {
            name: "E028 Esthétique-cosmétique et enseignement associé",
            id: "E028 Esthétique-cosmétique et enseignement associé",
        },
        {
            name: "E029 Exploitations agricoles de Saône-et-Loire",
            id: "E029 Exploitations agricoles de Saône-et-Loire",
        },
        {
            name: "E032 Exploitations agricoles de l'Hérault",
            id: "E032 Exploitations agricoles de l'Hérault",
        },
        {
            name: "E033 Exploitations de polyculture, d'élevage de viticulture, de maraichage, des CUMA et ETAR d'Indre et Loire",
            id: "E033 Exploitations de polyculture, d'élevage de viticulture, de maraichage, des CUMA et ETAR d'Indre et Loire",
        },
        {
            name: "E034 Exploitations agricoles du département de l'Ain",
            id: "E034 Exploitations agricoles du département de l'Ain",
        },
        {
            name: "E035 Exploitations de pépinières et d'horticulture de la région Midi-Pyrénées",
            id: "E035 Exploitations de pépinières et d'horticulture de la région Midi-Pyrénées",
        },
        {
            name: "E036 Exploitations et entreprises du secteur de la production agricole du Loir et Cher",
            id: "E036 Exploitations et entreprises du secteur de la production agricole du Loir et Cher",
        },
        {
            name: "E037 Exploitations pépinières et d'horticulture du Loir et Cher",
            id: "E037 Exploitations pépinières et d'horticulture du Loir et Cher",
        },
        {
            name: "E038 Entreprises de battages, de travaux agricoles et CUMA du Loir et Cher",
            id: "E038 Entreprises de battages, de travaux agricoles et CUMA du Loir et Cher",
        },
        {
            name: "E039 Exploitations et entreprises de la production agricole du Var",
            id: "E039 Exploitations et entreprises de la production agricole du Var",
        },
        {
            name: "E040 Entreprises de travaux agricoles et ruraux, Aube Marne Polyculture horticulture pépinières maraîchage Marne",
            id: "E040 Entreprises de travaux agricoles et ruraux, Aube Marne Polyculture horticulture pépinières maraîchage Marne",
        },
        {
            name: "E041 Exploitations agricoles, ETAF et CUMA de Franche-Comté",
            id: "E041 Exploitations agricoles, ETAF et CUMA de Franche-Comté",
        },
        {
            name: "E042 Exploitations et entreprises agricoles de Côte d'or, Nièvre et Yonne",
            id: "E042 Exploitations et entreprises agricoles de Côte d'or, Nièvre et Yonne",
        },
        {
            name: "E043 Exploitations agricoles de polyculture et d'élevage du Loiret",
            id: "E043 Exploitations agricoles de polyculture et d'élevage du Loiret",
        },
        {
            name: "E044 Exploitations agricoles et coopératives d'utilisation de matériels agricoles (CUMA) du département des Bouches-du-Rhône",
            id: "E044 Exploitations agricoles et coopératives d'utilisation de matériels agricoles (CUMA) du département des Bouches-du-Rhône",
        },
        {
            name: "E045 Exploitations agricoles et sociétés coopératives d'utilisation de matériel agricole (CUMA) du Vaucluse",
            id: "E045 Exploitations agricoles et sociétés coopératives d'utilisation de matériel agricole (CUMA) du Vaucluse",
        },
        {
            name: "E046 Exploitations agricoles de la Gironde",
            id: "E046 Exploitations agricoles de la Gironde",
        },
        {
            name: "E047 Exploitations agricoles Drôme",
            id: "E047 Exploitations agricoles Drôme",
        },
        {
            name: "E048 Exploitations et entreprises agricoles du département du Puy du Dôme",
            id: "E048 Exploitations et entreprises agricoles du département du Puy du Dôme",
        },
        {
            name: "E049 Exploitations horticoles et pépinières de la Haute-Savoie",
            id: "E049 Exploitations horticoles et pépinières de la Haute-Savoie",
        },
        {
            name: "E050 Exploitations d'arboriculture fruitière d'Indre et Loire",
            id: "E050 Exploitations d'arboriculture fruitière d'Indre et Loire",
        },
        {
            name: "E051 Exploitations agricoles des Alpes-de-Haute-Provence",
            id: "E051 Exploitations agricoles des Alpes-de-Haute-Provence",
        },
        {
            name: "E052 Exploitations viticoles de la Champagne",
            id: "E052 Exploitations viticoles de la Champagne",
        },
        {
            name: "E053 Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",
            id: "E053 Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",
        },
        {
            name: "E054 Exploitations et entreprises agricoles de la région Alsace",
            id: "E054 Exploitations et entreprises agricoles de la région Alsace",
        },
        {
            name: "E055 Exploitations horticoles et des pépinières d'Indre et Loire",
            id: "E055 Exploitations horticoles et des pépinières d'Indre et Loire",
        },
        {
            name: "E056 Entreprises de la production agricole de l'Allier",
            id: "E056 Entreprises de la production agricole de l'Allier",
        },
        {
            name: "E057 Exploitations et entreprises agricoles Rhône",
            id: "E057 Exploitations et entreprises agricoles Rhône",
        },
        {
            name: "E058 Exploitations agricoles, Coopératives d'utilisation de matériel agricole (CUMA), Entreprises de travaux agricoles et ruraux du Gers",
            id: "E058 Exploitations agricoles, Coopératives d'utilisation de matériel agricole (CUMA), Entreprises de travaux agricoles et ruraux du Gers",
        },
        {
            name: "E059 Exploitations forestières, sylvicoles et scieries agricoles du Centre",
            id: "E059 Exploitations forestières, sylvicoles et scieries agricoles du Centre",
        },
        {
            name: "E060 Exploitations forestières de la région Midi-Pyrénées",
            id: "E060 Exploitations forestières de la région Midi-Pyrénées",
        },
        {
            name: "E061 Exploitations agricoles et horticoles des Pyrénées-Atlantiques",
            id: "E061 Exploitations agricoles et horticoles des Pyrénées-Atlantiques",
        },
        {
            name: "E062 Exploitations agricoles du département des Landes",
            id: "E062 Exploitations agricoles du département des Landes",
        },
        {
            name: "E063 Exploitations agricoles de la Loire",
            id: "E063 Exploitations agricoles de la Loire",
        },
        {
            name: "E064 Exploitations de polyculture, d'élevage, de viticulture, d'arboriculture, de travaux agricoles et CUMA d'Indre",
            id: "E064 Exploitations de polyculture, d'élevage, de viticulture, d'arboriculture, de travaux agricoles et CUMA d'Indre",
        },
        {
            name: "E065 Exploitations agricoles de la Haute-Garonne",
            id: "E065 Exploitations agricoles de la Haute-Garonne",
        },
        {
            name: "E066 Entreprises de travaux agricoles et ruraux, Vienne Deux-Sèvres, Production agricole Vienne",
            id: "E066 Entreprises de travaux agricoles et ruraux, Vienne Deux-Sèvres, Production agricole Vienne",
        },
        {
            name: "E067 Exploitations polyculture et élevage du Nord",
            id: "E067 Exploitations polyculture et élevage du Nord",
        },
        {
            name: "E068 Entreprises de travaux agricoles et ruraux de la région Pays de la Loire",
            id: "E068 Entreprises de travaux agricoles et ruraux de la région Pays de la Loire",
        },
        {
            name: "E069 Exploitations de polyculture et d'élevage d'Eure-et-Loir",
            id: "E069 Exploitations de polyculture et d'élevage d'Eure-et-Loir",
        },
        {
            name: "E070 Exploitations forestières Alsace",
            id: "E070 Exploitations forestières Alsace",
        },
        {
            name: "E071 Entreprises de travaux agricoles et ruraux, Nord Pas de Calais",
            id: "E071 Entreprises de travaux agricoles et ruraux, Nord Pas de Calais",
        },
        {
            name: "E072 Exploitations forestières du massif de Gascogne",
            id: "E072 Exploitations forestières du massif de Gascogne",
        },
        {
            name: "E073 Exploitations agricoles du département de la Dordogne",
            id: "E073 Exploitations agricoles du département de la Dordogne",
        },
        {
            name: "E074 Exploitations horticoles et pépinières du Loiret (fusion avec E191 : Exploitations de cultures spécialisées du Loiret)",
            id: "E074 Exploitations horticoles et pépinières du Loiret (fusion avec E191 : Exploitations de cultures spécialisées du Loiret)",
        },
        {
            name: "E075 Exploitations de polyculture de viticulture et d'élevage de Maine-et-Loire",
            id: "E075 Exploitations de polyculture de viticulture et d'élevage de Maine-et-Loire",
        },
        {
            name: "E076 Exploitations horticoles et des pépinières de Maine-et-Loire",
            id: "E076 Exploitations horticoles et des pépinières de Maine-et-Loire",
        },
        {
            name: "E077 Exploitations horticoles et des pépinières de la Sarthe",
            id: "E077 Exploitations horticoles et des pépinières de la Sarthe",
        },
        {
            name: "E078 Exploitations agricoles de polyculture-élevage et entreprises de travaux agricoles du département de la Somme",
            id: "E078 Exploitations agricoles de polyculture-élevage et entreprises de travaux agricoles du département de la Somme",
        },
        {
            name: "E079 Exploitations agricoles et CUMA du département des Hautes-Alpes",
            id: "E079 Exploitations agricoles et CUMA du département des Hautes-Alpes",
        },
        {
            name: "E080 Exploitations agricoles des Alpes-Maritimes",
            id: "E080 Exploitations agricoles des Alpes-Maritimes",
        },
        {
            name: "E081 Exploitations forestières scieries agricoles et propriété forestière (Allier Cantal Gard Haute-Loire Loire Lozere Puy du Dôme)",
            id: "E081 Exploitations forestières scieries agricoles et propriété forestière (Allier Cantal Gard Haute-Loire Loire Lozere Puy du Dôme)",
        },
        {
            name: "E082 Exploitations agricoles, entreprises de travaux agricoles et Cuma (Savoie et Haute-Savoie)",
            id: "E082 Exploitations agricoles, entreprises de travaux agricoles et Cuma (Savoie et Haute-Savoie)",
        },
        {
            name: "E083 Exploitations agricoles du Gard",
            id: "E083 Exploitations agricoles du Gard",
        },
        {
            name: "E084 Entreprises de travaux agricoles et ruraux de Haute-Normandie",
            id: "E084 Entreprises de travaux agricoles et ruraux de Haute-Normandie",
        },
        {
            name: "E085 Exploitations de polyculture et d'élevage, CUMA et exploitations de cultures spécialisées de Haute-Marne",
            id: "E085 Exploitations de polyculture et d'élevage, CUMA et exploitations de cultures spécialisées de Haute-Marne",
        },
        {
            name: "E086 Exploitations forestières et scieries agricoles du Rhône-Alpes",
            id: "E086 Exploitations forestières et scieries agricoles du Rhône-Alpes",
        },
        {
            name: "E087 Entreprises agricoles de polyculture, élevage, viticulture, horticulture, pépinières, de travaux agricoles et CUMA de la Charente",
            id: "E087 Entreprises agricoles de polyculture, élevage, viticulture, horticulture, pépinières, de travaux agricoles et CUMA de la Charente",
        },
        {
            name: "E088 Entreprises de travaux agricoles et ruraux, CUMA (Tarn Haute-Garonne)",
            id: "E088 Entreprises de travaux agricoles et ruraux, CUMA (Tarn Haute-Garonne)",
        },
        {
            name: "E089 Exploitations et entreprises de travaux agricoles et CUMA de l'Ardèche",
            id: "E089 Exploitations et entreprises de travaux agricoles et CUMA de l'Ardèche",
        },
        {
            name: "E090 Entreprises de travaux agricoles ruraux et forestiers de la région Languedoc-Roussillon et Vaucluse",
            id: "E090 Entreprises de travaux agricoles ruraux et forestiers de la région Languedoc-Roussillon et Vaucluse",
        },
        {
            name: "E091 Scieries agricoles et exploitations forestières du Limousin",
            id: "E091 Scieries agricoles et exploitations forestières du Limousin",
        },
        {
            name: "E092 Exploitations agricoles de polyculture, d'élevage, de cultures spécialisées, les ETA et forestiers, d'arboriculture et les CUMA  de la Haute-Vienne",
            id: "E092 Exploitations agricoles de polyculture, d'élevage, de cultures spécialisées, les ETA et forestiers, d'arboriculture et les CUMA  de la Haute-Vienne",
        },
        {
            name: "E093 Eglise de France (statut du personnel laïc)",
            id: "E093 Eglise de France (statut du personnel laïc)",
        },
        {
            name: "E094 Exploitations agricoles de Haute Corse",
            id: "E094 Exploitations agricoles de Haute Corse",
        },
        {
            name: "E095 Exploitations agricoles de l'Aisne",
            id: "E095 Exploitations agricoles de l'Aisne",
        },
        {
            name: "E096 Exploitations agricoles de polyculture, d'élevage et de cultures spécialisées et CUMA de Corrèze",
            id: "E096 Exploitations agricoles de polyculture, d'élevage et de cultures spécialisées et CUMA de Corrèze",
        },
        {
            name: "E097 Exploitations agricoles et de maraichage, les ETA et ETF et les CUMA de l'Allier",
            id: "E097 Exploitations agricoles et de maraichage, les ETA et ETF et les CUMA de l'Allier",
        },
        {
            name: "E098 Personnel d'encadrement des exploitations agricoles de Picardie",
            id: "E098 Personnel d'encadrement des exploitations agricoles de Picardie",
        },
        {
            name: "E099 Exploitations agricoles de Lot-et-Garonne",
            id: "E099 Exploitations agricoles de Lot-et-Garonne",
        },
        {
            name: "E100 Exploitations de polyculture et d'élevage d'Ille et Vilaine",
            id: "E100 Exploitations de polyculture et d'élevage d'Ille et Vilaine",
        },
        {
            name: "E101 Exploitations de polyculture, d'élevage, de cultures légumières de plein champ des Côtes d'Armor",
            id: "E101 Exploitations de polyculture, d'élevage, de cultures légumières de plein champ des Côtes d'Armor",
        },
        {
            name: "E102 Exploitations forestières de l'Aisne",
            id: "E102 Exploitations forestières de l'Aisne",
        },
        {
            name: "E103 Exploitations de polyculture et d'élevage (Ile-de-France sauf Seine-et-Marne)",
            id: "E103 Exploitations de polyculture et d'élevage (Ile-de-France sauf Seine-et-Marne)",
        },
        {
            name: "E104 Exploitations de polyculture et d'élevage : personnel d'encadrement (Ile-de-France)",
            id: "E104 Exploitations de polyculture et d'élevage : personnel d'encadrement (Ile-de-France)",
        },
        {
            name: "E105 Exploitations de polyculture et d'élevage, entreprises de travaux agricoles et ruraux, et CUMA (Oise)",
            id: "E105 Exploitations de polyculture et d'élevage, entreprises de travaux agricoles et ruraux, et CUMA (Oise)",
        },
        {
            name: "E106 Exploitations agricoles de la Corse du Sud",
            id: "E106 Exploitations agricoles de la Corse du Sud",
        },
        {
            name: "E107 Exploitations de polyculture, de viticulture et d'élevage de Loire-Atlantique",
            id: "E107 Exploitations de polyculture, de viticulture et d'élevage de Loire-Atlantique",
        },
        {
            name: "E108 Exploitations sylvicoles de la région Pays de la Loire",
            id: "E108 Exploitations sylvicoles de la région Pays de la Loire",
        },
        {
            name: "E109 Exploitations forestières et scieries agricoles des départements de la région Pays de la Loire",
            id: "E109 Exploitations forestières et scieries agricoles des départements de la région Pays de la Loire",
        },
        {
            name: "E110 Exploitations forestières de l'Oise",
            id: "E110 Exploitations forestières de l'Oise",
        },
        {
            name: "E111 Exploitations forestières et scieries agricoles de la région Nord-Pas-de-Calais",
            id: "E111 Exploitations forestières et scieries agricoles de la région Nord-Pas-de-Calais",
        },
        {
            name: "E112 Exploitations agricoles du Cher",
            id: "E112 Exploitations agricoles du Cher",
        },
        {
            name: "E113 Exploitations de cultures maraichères, de pépinières et d'horticulture du Nord",
            id: "E113 Exploitations de cultures maraichères, de pépinières et d'horticulture du Nord",
        },
        {
            name: "E114 Exploitations de cultures spécialisées du département Pas-de-Calais",
            id: "E114 Exploitations de cultures spécialisées du département Pas-de-Calais",
        },
        {
            name: "E115 Exploitations de polyculture et d'élevage, coopératives d'utilisation de matériel agricole (CUMA) du département Pas-de-Calais",
            id: "E115 Exploitations de polyculture et d'élevage, coopératives d'utilisation de matériel agricole (CUMA) du département Pas-de-Calais",
        },
        {
            name: "E116 Exploitations agricoles, d'élevages, entreprises de travaux agricoles, CUMA du Tarn-et-Garonne",
            id: "E116 Exploitations agricoles, d'élevages, entreprises de travaux agricoles, CUMA du Tarn-et-Garonne",
        },
        {
            name: "E117 Exploitations agricoles, entreprises de travaux agricoles et ruraux de la Creuse",
            id: "E117 Exploitations agricoles, entreprises de travaux agricoles et ruraux de la Creuse",
        },
        {
            name: "E118 Exploitations agricoles du Lot",
            id: "E118 Exploitations agricoles du Lot",
        },
        {
            name: "E119 Exploitations agricoles du Tarn",
            id: "E119 Exploitations agricoles du Tarn",
        },
        {
            name: "E120 Exploitations de polyculture et d'élevage de la Mayenne",
            id: "E120 Exploitations de polyculture et d'élevage de la Mayenne",
        },
        {
            name: "E121 Entreprises de travaux agricoles et ruraux (ETAR) de Bretagne",
            id: "E121 Entreprises de travaux agricoles et ruraux (ETAR) de Bretagne",
        },
        {
            name: "E122 Exploitations horticoles et pépinières de la Vendée",
            id: "E122 Exploitations horticoles et pépinières de la Vendée",
        },
        {
            name: "E123 Exploitations de Polyculture, de viticulture et d'élevage de la Vendée",
            id: "E123 Exploitations de Polyculture, de viticulture et d'élevage de la Vendée",
        },
        {
            name: "E124 Productions et travaux agricoles de l'Isère",
            id: "E124 Productions et travaux agricoles de l'Isère",
        },
        {
            name: "E125 Exploitations de polyculture, d'élevage, de cultures légumières, maraîchères et CUMA de la Manche",
            id: "E125 Exploitations de polyculture, d'élevage, de cultures légumières, maraîchères et CUMA de la Manche",
        },
        {
            name: "E126 Exploitations de polyculture, d'élevage et de maraîchage du Finistère",
            id: "E126 Exploitations de polyculture, d'élevage et de maraîchage du Finistère",
        },
        {
            name: "E127 Exploitations de polyculture, d'élevage, de cultures spécialisées et CUMA des Deux-Sèvres",
            id: "E127 Exploitations de polyculture, d'élevage, de cultures spécialisées et CUMA des Deux-Sèvres",
        },
        {
            name: "E128 Exploitations forestières et scieries agricoles de la région de Bretagne",
            id: "E128 Exploitations forestières et scieries agricoles de la région de Bretagne",
        },
        {
            name: "E129 Exploitations agricoles, de polyculture, d'élevage, d'aviculture et CUMA de Seine-et-Marne et Entreprises de travaux agricoles, ETR, et ETF d'Ile-de-France",
            id: "E129 Exploitations agricoles, de polyculture, d'élevage, d'aviculture et CUMA de Seine-et-Marne et Entreprises de travaux agricoles, ETR, et ETF d'Ile-de-France",
        },
        {
            name: "E130 Exploitations de polyculture et d'élevage de la Seine-Maritime",
            id: "E130 Exploitations de polyculture et d'élevage de la Seine-Maritime",
        },
        {
            name: "E131 Scieries agricoles et activités connexes des régions Alsace et Lorraine",
            id: "E131 Scieries agricoles et activités connexes des régions Alsace et Lorraine",
        },
        {
            name: "E132 Exploitations forestières de la Moselle",
            id: "E132 Exploitations forestières de la Moselle",
        },
        {
            name: "E133 Exploitations forestières de la Champagne-Ardenne",
            id: "E133 Exploitations forestières de la Champagne-Ardenne",
        },
        {
            name: "E134 Exploitations forestières de Meurthe-et-Moselle et des Vosges",
            id: "E134 Exploitations forestières de Meurthe-et-Moselle et des Vosges",
        },
        {
            name: "E135 Entreprises de travaux agricoles et ruraux de Basse-Normandie",
            id: "E135 Entreprises de travaux agricoles et ruraux de Basse-Normandie",
        },
        {
            name: "E136 Exploitations agricoles du Morbihan",
            id: "E136 Exploitations agricoles du Morbihan",
        },
        {
            name: "E138 Exploitations de polyculture, d'élevage, de viticulture, des CUMA, des champignonnières, des ETAR et de maraichage des Hautes-Pyrénées",
            id: "E138 Exploitations de polyculture, d'élevage, de viticulture, des CUMA, des champignonnières, des ETAR et de maraichage des Hautes-Pyrénées",
        },
        {
            name: "E139 Exploitants et propriétaires forestiers, sylviculteurs du Doubs, du Jura, de la Haute-Saône et du Territoire de Belfort",
            id: "E139 Exploitants et propriétaires forestiers, sylviculteurs du Doubs, du Jura, de la Haute-Saône et du Territoire de Belfort",
        },
        {
            name: "E140 Exploitations maraîchères de Loire-Atlantique",
            id: "E140 Exploitations maraîchères de Loire-Atlantique",
        },
        {
            name: "E141 Exploitations agricoles de l'Eure",
            id: "E141 Exploitations agricoles de l'Eure",
        },
        {
            name: "E142 Entreprises horticoles et pépiniéristes d'Ille-et-Vilaine",
            id: "E142 Entreprises horticoles et pépiniéristes d'Ille-et-Vilaine",
        },
        {
            name: "E143 Exploitations agricoles de l'Ariège",
            id: "E143 Exploitations agricoles de l'Ariège",
        },
        {
            name: "E144 Exploitations de polyculture, d'élevage, de maraichage, d'arboriculture fruitière, Haras & CUMA de l'Orne",
            id: "E144 Exploitations de polyculture, d'élevage, de maraichage, d'arboriculture fruitière, Haras & CUMA de l'Orne",
        },
        {
            name: "E145 Exploitations de pépinières et d'horticulture de la Manche",
            id: "E145 Exploitations de pépinières et d'horticulture de la Manche",
        },
        {
            name: "E146 Exploitations agricoles des Pyrénées-Orientales",
            id: "E146 Exploitations agricoles des Pyrénées-Orientales",
        },
        {
            name: "E147 Exploitations de maraichage, d'arboriculture, d'horticulture, de pépinières et de cressiculture d'Ile-de-France",
            id: "E147 Exploitations de maraichage, d'arboriculture, d'horticulture, de pépinières et de cressiculture d'Ile-de-France",
        },
        {
            name: "E148 Entreprises et exploitations de l'horticulture, pépinières, l'arboriculture, production de fruits et champignons du Calvados",
            id: "E148 Entreprises et exploitations de l'horticulture, pépinières, l'arboriculture, production de fruits et champignons du Calvados",
        },
        {
            name: "E149 Exploitations forestières de Basse-Normandie et des propriétaires forestiers sylviculteurs de l'Orne",
            id: "E149 Exploitations forestières de Basse-Normandie et des propriétaires forestiers sylviculteurs de l'Orne",
        },
        {
            name: "E150 Exploitations de polyculture, d'élevage, de culture, entreprises de travaux agricoles, ruraux, forestiers, CUMA du Cantal",
            id: "E150 Exploitations de polyculture, d'élevage, de culture, entreprises de travaux agricoles, ruraux, forestiers, CUMA du Cantal",
        },
        {
            name: "E151 Exploitations agricoles de la zone céréalière de l'Aude",
            id: "E151 Exploitations agricoles de la zone céréalière de l'Aude",
        },
        {
            name: "E152 Exploitations de polyculture, d'élevage, de production fruitière et viticole, entreprises de travaux agricoles, d'aménagements ruraux et forestiers de la Meuse",
            id: "E152 Exploitations de polyculture, d'élevage, de production fruitière et viticole, entreprises de travaux agricoles, d'aménagements ruraux et forestiers de la Meuse",
        },
        {
            name: "E153 Entreprises de travaux d'aménagement et d'entretien forestiers de Gironde, des Landes et du Lot-et-Garonne",
            id: "E153 Entreprises de travaux d'aménagement et d'entretien forestiers de Gironde, des Landes et du Lot-et-Garonne",
        },
        {
            name: "E154 Exploitations agricoles de polyculture et d'élevage, entreprises de travaux agricoles, CUMA de l'Aveyron",
            id: "E154 Exploitations agricoles de polyculture et d'élevage, entreprises de travaux agricoles, CUMA de l'Aveyron",
        },
        {
            name: "E155 Exploitations agricoles et CUMA (Cadres) des Bouches-du-Rhône",
            id: "E155 Exploitations agricoles et CUMA (Cadres) des Bouches-du-Rhône",
        },
        {
            name: "E156 Exploitations forestières de la Meuse",
            id: "E156 Exploitations forestières de la Meuse",
        },
        {
            name: "E157 Exploitations de polyculture et d'élevage des Vosges",
            id: "E157 Exploitations de polyculture et d'élevage des Vosges",
        },
        {
            name: "E158 Exploitations et entreprises champignonnistes de l'Aisne et de l'Oise",
            id: "E158 Exploitations et entreprises champignonnistes de l'Aisne et de l'Oise",
        },
        {
            name: "E159 Exploitations agricoles et CUMA de l'Aube",
            id: "E159 Exploitations agricoles et CUMA de l'Aube",
        },
        {
            name: "E160 Branches spécialisées de l'horticulture et des pépinières de l'Orne",
            id: "E160 Branches spécialisées de l'horticulture et des pépinières de l'Orne",
        },
        {
            name: "E161 Entreprises agricoles de déshydratation",
            id: "E161 Entreprises agricoles de déshydratation",
        },
        {
            name: "E162 Exploitations et entreprises agricoles des Ardennes",
            id: "E162 Exploitations et entreprises agricoles des Ardennes",
        },
        {
            name: "E163 Production agricole, entreprises de travaux agricoles et CUMA (Cadres) du Rhône-Alpes",
            id: "E163 Production agricole, entreprises de travaux agricoles et CUMA (Cadres) du Rhône-Alpes",
        },
        {
            name: "E164 Exploitations et entreprises agricoles de la Haute-Loire et de la Lozère",
            id: "E164 Exploitations et entreprises agricoles de la Haute-Loire et de la Lozère",
        },
        {
            name: "E166 Exploitations de polyculture, d'élevage et de production de fruits, entreprises de travaux agricoles, d'aménagement ruraux et forestier, CUMA de Meurthe-et-Moselle",
            id: "E166 Exploitations de polyculture, d'élevage et de production de fruits, entreprises de travaux agricoles, d'aménagement ruraux et forestier, CUMA de Meurthe-et-Moselle",
        },
        {
            name: "E167 Entreprises d'horticulture, pépinières, maraîchage de Franche-Comté",
            id: "E167 Entreprises d'horticulture, pépinières, maraîchage de Franche-Comté",
        },
        {
            name: "E168 Exploitations horticoles, maraichères, de pépinières et d'arboriculture fruitière d'Eure-et-Loir",
            id: "E168 Exploitations horticoles, maraichères, de pépinières et d'arboriculture fruitière d'Eure-et-Loir",
        },
        {
            name: "E169 Exploitations forestières de la Côte-d'Or",
            id: "E169 Exploitations forestières de la Côte-d'Or",
        },
        {
            name: "E170 Exploitations forestières de Saône-et-Loire",
            id: "E170 Exploitations forestières de Saône-et-Loire",
        },
        {
            name: "E171 Exploitations maraîchères d'Ille-et-Vilaine et du Morbihan",
            id: "E171 Exploitations maraîchères d'Ille-et-Vilaine et du Morbihan",
        },
        {
            name: "E172 Coopératives d'utilisation en commun de matériel agricole (CUMA) de la Loire",
            id: "E172 Coopératives d'utilisation en commun de matériel agricole (CUMA) de la Loire",
        },
        {
            name: "E173 Exploitations agricoles (Cadres) du Gard",
            id: "E173 Exploitations agricoles (Cadres) du Gard",
        },
        {
            name: "E174 Exploitations forestières et scieries agricoles de la région Poitou-Charentes",
            id: "E174 Exploitations forestières et scieries agricoles de la région Poitou-Charentes",
        },
        {
            name: "E175 Exploitations d'horticulture, de pépinières et de maraîchage des Côtes-d'Armor",
            id: "E175 Exploitations d'horticulture, de pépinières et de maraîchage des Côtes-d'Armor",
        },
        {
            name: "E176 Exploitations forestières de la Seine-Maritime et de l'Eure",
            id: "E176 Exploitations forestières de la Seine-Maritime et de l'Eure",
        },
        {
            name: "E177 Coopératives d'utilisation de matériel agricole (CUMA) de Bretagne et des Pays de la Loire",
            id: "E177 Coopératives d'utilisation de matériel agricole (CUMA) de Bretagne et des Pays de la Loire",
        },
        {
            name: "E178 Exploitations maraichères de la Vendée",
            id: "E178 Exploitations maraichères de la Vendée",
        },
        {
            name: "E179 Exploitations de polyculture, d'élevage et des CUMA de la Moselle",
            id: "E179 Exploitations de polyculture, d'élevage et des CUMA de la Moselle",
        },
        {
            name: "E180 Exploitations de polyculture, d'élevage, maraîchères et de cultures légumières de plein champ (Cadres) de l'Eure ",
            id: "E180 Exploitations de polyculture, d'élevage, maraîchères et de cultures légumières de plein champ (Cadres) de l'Eure ",
        },
        {
            name: "E181 Exploitations horticoles et pépinières du Finistère",
            id: "E181 Exploitations horticoles et pépinières du Finistère",
        },
        {
            name: "E182 Exploitations forestières des Alpes-Maritimes",
            id: "E182 Exploitations forestières des Alpes-Maritimes",
        },
        {
            name: "E183 Etablissements producteurs de graines de semences potagères et florales de Maine-et-Loire",
            id: "E183 Etablissements producteurs de graines de semences potagères et florales de Maine-et-Loire",
        },
        {
            name: "E184 Exploitations maraichères et légumières de plein champ de Seine-Maritime",
            id: "E184 Exploitations maraichères et légumières de plein champ de Seine-Maritime",
        },
        {
            name: "E185 Exploitations agricoles de polyculture, élevage, culture/élevage spécialisés, viticulture, CUMA, ETA et ETF de Charente-Maritime",
            id: "E185 Exploitations agricoles de polyculture, élevage, culture/élevage spécialisés, viticulture, CUMA, ETA et ETF de Charente-Maritime",
        },
        {
            name: "E186 Exploitations maraîchères de la Sarthe",
            id: "E186 Exploitations maraîchères de la Sarthe",
        },
        {
            name: "E187 Exploitations horticoles de Haute-Normandie",
            id: "E187 Exploitations horticoles de Haute-Normandie",
        },
        {
            name: "E188 Etablissement Français de Sang",
            id: "E188 Etablissement Français de Sang",
        },
        {
            name: "E189 Exploitations horticoles, maraichères et pépinières de la Mayenne",
            id: "E189 Exploitations horticoles, maraichères et pépinières de la Mayenne",
        },
        {
            name: "E191 Exploitations de cultures spécialisées du Loiret",
            id: "E191 Exploitations de cultures spécialisées du Loiret",
        },
        {
            name: "E192 Exploitations de cultures légumières de Maine-et-Loire",
            id: "E192 Exploitations de cultures légumières de Maine-et-Loire",
        },
        {
            name: "E193 Exploitations d'horticulture et des pépinières du Morbihan",
            id: "E193 Exploitations d'horticulture et des pépinières du Morbihan",
        },
        {
            name: "E194 Exploitations forestières de la Nièvre",
            id: "E194 Exploitations forestières de la Nièvre",
        },
        {
            name: "E195 Etablissements agricoles privés relevant du Conseil National de l'Enseignement Agricole Privé (personnel des)",
            id: "E195 Etablissements agricoles privés relevant du Conseil National de l'Enseignement Agricole Privé (personnel des)",
        },
        {
            name: "E196 Exploitations horticoles et pépinières de Loire-Atlantique",
            id: "E196 Exploitations horticoles et pépinières de Loire-Atlantique",
        },
        {
            name: "E198 Exploitations d'horticulture et de pépinière de Lot-et-Garonne",
            id: "E198 Exploitations d'horticulture et de pépinière de Lot-et-Garonne",
        },
        {
            name: "E200 Exploitations forestières de la Somme",
            id: "E200 Exploitations forestières de la Somme",
        },
        {
            name: "E201 Exploitations forestières de la Haute-Saône",
            id: "E201 Exploitations forestières de la Haute-Saône",
        },
        {
            name: "E202  Exploitations horticoles, maraichères et de pépinières (Meurthe-et-Moselle, Meuse, Moselle et Vosges) (ex : Exploitations maraichères et de serres de Meurthe-et-Moselle et de Moselle)",
            id: "E202  Exploitations horticoles, maraichères et de pépinières (Meurthe-et-Moselle, Meuse, Moselle et Vosges) (ex : Exploitations maraichères et de serres de Meurthe-et-Moselle et de Moselle)",
        },
        {
            name: "E203 Exploitations forestières de Lorraine",
            id: "E203 Exploitations forestières de Lorraine",
        },
        {
            name: "E204 Exploitations forestières de l'Yonne",
            id: "E204 Exploitations forestières de l'Yonne",
        },
        {
            name: "E205 Exploitations horticoles et pépinières de l'Indre",
            id: "E205 Exploitations horticoles et pépinières de l'Indre",
        },
        {
            name: "E206 Scieries agricoles et activités connexes de Champagne-Ardenne",
            id: "E206 Scieries agricoles et activités connexes de Champagne-Ardenne",
        },
        {
            name: "E207 Exploitations de cultures spécialisées de l'Oise",
            id: "E207 Exploitations de cultures spécialisées de l'Oise",
        },
        {
            name: "E208 Exploitations de polyculture, d'élevage, fruitières et viticoles, entreprises de travaux agricoles et CUMA de Lorraine",
            id: "E208 Exploitations de polyculture, d'élevage, fruitières et viticoles, entreprises de travaux agricoles et CUMA de Lorraine",
        },
        {
            name: "E209 Pépinières sylvicoles de la Creuse",
            id: "E209 Pépinières sylvicoles de la Creuse",
        },
        {
            name: "E210 Exploitations bananières de la Martinique",
            id: "E210 Exploitations bananières de la Martinique",
        },
        {
            name: "E211 Personnel non enseignant des établissements protestants de Polynésie Française",
            id: "E211 Personnel non enseignant des établissements protestants de Polynésie Française",
        },
        {
            name: "E212 Production Agricole et CUMA",
            id: "E212 Production Agricole et CUMA",
        },
        {
            name: "E213 Entreprises de Travaux et Services Agricoles, Ruraux et Forestiers (ETARF)",
            id: "E213 Entreprises de Travaux et Services Agricoles, Ruraux et Forestiers (ETARF)",
        },
        {
            name: "ENIM Marins sans CCN : pêche et commerce/plaisance",
            id: "ENIM Marins sans CCN : pêche et commerce/plaisance",
        },
        { name: "F001 Familles rurales", id: "F001 Familles rurales" },
        {
            name: "F002 Fleuristes, vente et services des animaux familiers",
            id: "F002 Fleuristes, vente et services des animaux familiers",
        },
        {
            name: "F003 Fleurs, fruits et légumes (coopératives agricoles et SICA)",
            id: "F003 Fleurs, fruits et légumes (coopératives agricoles et SICA)",
        },
        {
            name: "F004 Football (personnels administratifs et assimilés)",
            id: "F004 Football (personnels administratifs et assimilés)",
        },
        {
            name: "F005 Formation (organismes)",
            id: "F005 Formation (organismes)",
        },
        {
            name: "F006 Fourrure (fusion avec C084 : Couture parisienne)",
            id: "F006 Fourrure (fusion avec C084 : Couture parisienne)",
        },
        {
            name: "F007 Habitat et  logement accompagnés (ex : Foyers de jeunes travailleurs)",
            id: "F007 Habitat et  logement accompagnés (ex : Foyers de jeunes travailleurs)",
        },
        {
            name: "F008 Fruits et légumes (entreprises d'expédition et d'exportation)",
            id: "F008 Fruits et légumes (entreprises d'expédition et d'exportation)",
        },
        {
            name: "F009 Fruits, légumes, épicerie, produits laitiers (commerce de détail)",
            id: "F009 Fruits, légumes, épicerie, produits laitiers (commerce de détail)",
        },
        { name: "F010 Métiers du football", id: "F010 Métiers du football" },
        {
            name: "F011 Personnel du réseau FNAB",
            id: "F011 Personnel du réseau FNAB",
        },
        {
            name: "F012 Directeurs, Directeurs Adjoints et Sous-Directeurs des FDSEA-UDSEA et FRSEA affiliées à la FNSEA",
            id: "F012 Directeurs, Directeurs Adjoints et Sous-Directeurs des FDSEA-UDSEA et FRSEA affiliées à la FNSEA",
        },
        {
            name: "F013 Personnel du réseau FDSEA de la Drôme",
            id: "F013 Personnel du réseau FDSEA de la Drôme",
        },
        {
            name: "F014 Personnel du réseau FNSEA",
            id: "F014 Personnel du réseau FNSEA",
        },
        {
            name: "FPCT Statut de la Fonction Publique Territoriale",
            id: "FPCT Statut de la Fonction Publique Territoriale",
        },
        {
            name: "FPET Statut de la Fonction Publique d'Etat",
            id: "FPET Statut de la Fonction Publique d'Etat",
        },
        {
            name: "FPHP Statut de la Fonction Publique hospitalière",
            id: "FPHP Statut de la Fonction Publique hospitalière",
        },
        {
            name: "G001 Ganterie de peau (fusion avec M009 : Maroquinerie industries)",
            id: "G001 Ganterie de peau (fusion avec M009 : Maroquinerie industries)",
        },
        {
            name: "G002 Gardes-chasse et gardes-pêche particuliers",
            id: "G002 Gardes-chasse et gardes-pêche particuliers",
        },
        {
            name: "G003 Gardiens, concierges et employés d'immeubles",
            id: "G003 Gardiens, concierges et employés d'immeubles",
        },
        {
            name: "G004 Géomètres-experts, topographes",
            id: "G004 Géomètres-experts, topographes",
        },
        { name: "G006 Golf", id: "G006 Golf" },
        {
            name: "G007 Greffiers des tribunaux de commerce (personnel)",
            id: "G007 Greffiers des tribunaux de commerce (personnel)",
        },
        {
            name: "G008 Groupement des Armateurs de Service de Passage d'Eau (personnel officier)",
            id: "G008 Groupement des Armateurs de Service de Passage d'Eau (personnel officier)",
        },
        {
            name: "G009 Groupement des Armateurs de Service de Passage d'Eau (personnel navigant d'exécution)",
            id: "G009 Groupement des Armateurs de Service de Passage d'Eau (personnel navigant d'exécution)",
        },
        {
            name: "G010 Statut conventionnel du personnel Groupama",
            id: "G010 Statut conventionnel du personnel Groupama",
        },
        {
            name: "G011 Garages de la Martinique (personnel des)",
            id: "G011 Garages de la Martinique (personnel des)",
        },
        {
            name: "G012 Groupement des Armateurs de Service de Passage d'Eau (personnel navigant)",
            id: "G012 Groupement des Armateurs de Service de Passage d'Eau (personnel navigant)",
        },
        {
            name: "G013 Gardiennage, Entreprises de Prévention et Sécurité de Polynésie Française",
            id: "G013 Gardiennage, Entreprises de Prévention et Sécurité de Polynésie Française",
        },
        {
            name: "H001 Habillement (industrie)",
            id: "H001 Habillement (industrie)",
        },
        {
            name: "H002 Habillement (maisons à succursales de vente au détail)",
            id: "H002 Habillement (maisons à succursales de vente au détail)",
        },
        {
            name: "H003 Habillement et articles textiles (commerce de détail)",
            id: "H003 Habillement et articles textiles (commerce de détail)",
        },
        {
            name: "H004 Habillement et articles textiles (commerce de détail : Valenciennes)",
            id: "H004 Habillement et articles textiles (commerce de détail : Valenciennes)",
        },
        {
            name: "H005 Habitat (personnels PACT et ARIM) (fusion avec F007 : Habitat et  logement accompagnés (ex : Foyers de jeunes travailleurs))",
            id: "H005 Habitat (personnels PACT et ARIM) (fusion avec F007 : Habitat et  logement accompagnés (ex : Foyers de jeunes travailleurs))",
        },
        {
            name: "H006 Habitat social (organisations professionnelles)",
            id: "H006 Habitat social (organisations professionnelles)",
        },
        {
            name: "H007 Handicapés (établissements et services pour personnes inadaptées et handicapées)",
            id: "H007 Handicapés (établissements et services pour personnes inadaptées et handicapées)",
        },
        {
            name: "H008 Handicapés (établissements et services pour personnes inadaptées et handicapées ; médecins spécialisés) (fusion avec H007 : Handicapés (établissements et services pour personnes inadaptées et handicapées))",
            id: "H008 Handicapés (établissements et services pour personnes inadaptées et handicapées ; médecins spécialisés) (fusion avec H007 : Handicapés (établissements et services pour personnes inadaptées et handicapées))",
        },
        {
            name: "H009 Hélicoptères (personnel navigant technique des exploitants)",
            id: "H009 Hélicoptères (personnel navigant technique des exploitants)",
        },
        {
            name: "H010 HLM (personnels des sociétés anonymes et fondations)",
            id: "H010 HLM (personnels des sociétés anonymes et fondations)",
        },
        {
            name: "H011 HLM (personnels des sociétés coopératives) (fusion avec P082 : Personnel des offices publics de l'habitat)",
            id: "H011 HLM (personnels des sociétés coopératives) (fusion avec P082 : Personnel des offices publics de l'habitat)",
        },
        {
            name: "H012 Horlogerie (commerce de gros) (fusion avec B045 : Bijouterie, joaillerie, orfèvrerie)",
            id: "H012 Horlogerie (commerce de gros) (fusion avec B045 : Bijouterie, joaillerie, orfèvrerie)",
        },
        {
            name: "H013 Horlogerie-bijouterie (commerce de détail)",
            id: "H013 Horlogerie-bijouterie (commerce de détail)",
        },
        {
            name: "H014 Hospitalisation privée à but lucratif",
            id: "H014 Hospitalisation privée à but lucratif",
        },
        {
            name: "H015 Hospitalisation privée à but non lucratif",
            id: "H015 Hospitalisation privée à but non lucratif",
        },
        {
            name: "H016 Hôtellerie de plein air",
            id: "H016 Hôtellerie de plein air",
        },
        { name: "H017 Hôtels", id: "H017 Hôtels" },
        {
            name: "H018 Hôtels de tourisme (trois, quatre, quatre étoiles de luxe : région parisienne)",
            id: "H018 Hôtels de tourisme (trois, quatre, quatre étoiles de luxe : région parisienne)",
        },
        {
            name: "H019 Hôtels, cafés, restaurants",
            id: "H019 Hôtels, cafés, restaurants",
        },
        {
            name: "H020 Hôtels, cafés, restaurants (Alpes-de-Haute-Provence)",
            id: "H020 Hôtels, cafés, restaurants (Alpes-de-Haute-Provence)",
        },
        {
            name: "H021 Hôtels, cafés, restaurants (Bouches-du-Rhône)",
            id: "H021 Hôtels, cafés, restaurants (Bouches-du-Rhône)",
        },
        {
            name: "H022 Hôtels, cafés, restaurants (Haute-Saône)",
            id: "H022 Hôtels, cafés, restaurants (Haute-Saône)",
        },
        {
            name: "H023 Hôtels, cafés, restaurants (Meurthe-et-Moselle)",
            id: "H023 Hôtels, cafés, restaurants (Meurthe-et-Moselle)",
        },
        {
            name: "H024 Hôtels, cafés, restaurants (Saint-Pierre-et-Miquelon)",
            id: "H024 Hôtels, cafés, restaurants (Saint-Pierre-et-Miquelon)",
        },
        {
            name: "H025 Hôtels, cafés, restaurants (Seine Maritime)",
            id: "H025 Hôtels, cafés, restaurants (Seine Maritime)",
        },
        {
            name: "H026 Hôtels, cafés, restaurants (Var)",
            id: "H026 Hôtels, cafés, restaurants (Var)",
        },
        {
            name: "H027 Hôtels, cafés, restaurants (Vosges)",
            id: "H027 Hôtels, cafés, restaurants (Vosges)",
        },
        {
            name: "H028 Huissiers de justice (personnel)",
            id: "H028 Huissiers de justice (personnel)",
        },
        {
            name: "H029 Hospitalisation privée de la Guadeloupe",
            id: "H029 Hospitalisation privée de la Guadeloupe",
        },
        {
            name: "H030 Convention collective monégasque du travail du personnel des Hôtels, restaurants et débits de boissons",
            id: "H030 Convention collective monégasque du travail du personnel des Hôtels, restaurants et débits de boissons",
        },
        { name: "I001 Immobilier", id: "I001 Immobilier" },
        {
            name: "I002 Import-export et commerce international",
            id: "I002 Import-export et commerce international",
        },
        {
            name: "I003 Imprimeries de labeur et industries graphiques",
            id: "I003 Imprimeries de labeur et industries graphiques",
        },
        {
            name: "I005 Insertion (ateliers et chantiers)",
            id: "I005 Insertion (ateliers et chantiers)",
        },
        {
            name: "I006 Institutions d'assurance chômage",
            id: "I006 Institutions d'assurance chômage",
        },
        {
            name: "I007 Institutions de retraite complémentaire",
            id: "I007 Institutions de retraite complémentaire",
        },
        {
            name: "I008 Instruments à écrire et industries connexes (fusion avec C010 - Cartonnage : industries)",
            id: "I008 Instruments à écrire et industries connexes (fusion avec C010 - Cartonnage : industries)",
        },
        {
            name: "I009 Immobilier : diagnostic technique",
            id: "I009 Immobilier : diagnostic technique",
        },
        {
            name: "I010 Intermittents du spectacle (accords de branche du secteur)",
            id: "I010 Intermittents du spectacle (accords de branche du secteur)",
        },
        {
            name: "I011 Industries électriques et gazières (statut du personnel)",
            id: "I011 Industries électriques et gazières (statut du personnel)",
        },
        {
            name: "I012 Industrie de Roquefort",
            id: "I012 Industrie de Roquefort",
        },
        {
            name: "I013 Institut Français de la Vigne et du Vin (Personnel de)",
            id: "I013 Institut Français de la Vigne et du Vin (Personnel de)",
        },
        {
            name: "I014 Industrie de la Polynésie Française",
            id: "I014 Industrie de la Polynésie Française",
        },
        {
            name: "I015 Industrie Hôtelière de Polynésie Française",
            id: "I015 Industrie Hôtelière de Polynésie Française",
        },
        {
            name: "I016 Industrie sucrière et rhumière de la Martinique",
            id: "I016 Industrie sucrière et rhumière de la Martinique",
        },
        {
            name: "J001 Jardineries et graineteries",
            id: "J001 Jardineries et graineteries",
        },
        {
            name: "J003 Jeux, jouets, articles de fête",
            id: "J003 Jeux, jouets, articles de fête",
        },
        { name: "J005 Journalistes", id: "J005 Journalistes" },
        {
            name: "L001 Lait (coopératives agricoles et SICA)",
            id: "L001 Lait (coopératives agricoles et SICA)",
        },
        { name: "L002 Lait (industries)", id: "L002 Lait (industries)" },
        {
            name: "L003 Lait (organismes de contrôle)",
            id: "L003 Lait (organismes de contrôle)",
        },
        { name: "L004 Librairie", id: "L004 Librairie" },
        {
            name: "L005 Lin (rouissage-teillage)",
            id: "L005 Lin (rouissage-teillage)",
        },
        {
            name: "L006 Lin : teillage (coopératives agricoles et SICA)",
            id: "L006 Lin : teillage (coopératives agricoles et SICA)",
        },
        {
            name: "L007 Logistique de communication écrite directe",
            id: "L007 Logistique de communication écrite directe",
        },
        {
            name: "M002 Magasins (Grands) et magasins populaires",
            id: "M002 Magasins (Grands) et magasins populaires",
        },
        { name: "M003 Maisons d'étudiants", id: "M003 Maisons d'étudiants" },
        {
            name: "M004 Maisons familiales rurales",
            id: "M004 Maisons familiales rurales",
        },
        {
            name: "M005 Manutention ferroviaire et travaux connexes",
            id: "M005 Manutention ferroviaire et travaux connexes",
        },
        {
            name: "M007 Manutention portuaire (Guadeloupe)",
            id: "M007 Manutention portuaire (Guadeloupe)",
        },
        {
            name: "M008 Mareyeurs-expéditeurs",
            id: "M008 Mareyeurs-expéditeurs",
        },
        {
            name: "M009 Maroquinerie (industries)",
            id: "M009 Maroquinerie (industries)",
        },
        {
            name: "M013 Services de santé au travail interentreprises (SSTI)",
            id: "M013 Services de santé au travail interentreprises (SSTI)",
        },
        {
            name: "M014 Médico-sociaux (établissements)",
            id: "M014 Médico-sociaux (établissements)",
        },
        {
            name: "M015 Médico-techniques (négoce et prestations de services)",
            id: "M015 Médico-techniques (négoce et prestations de services)",
        },
        {
            name: "M016 Menuiseries, charpentes et constructions industrialisées",
            id: "M016 Menuiseries, charpentes et constructions industrialisées",
        },
        {
            name: "M017 Métallurgie (accords nationaux : ouvriers, ETAM, ingénieurs et cadres)",
            id: "M017 Métallurgie (accords nationaux : ouvriers, ETAM, ingénieurs et cadres)",
        },
        {
            name: "M018 Métallurgie (ingénieurs et cadres)",
            id: "M018 Métallurgie (ingénieurs et cadres)",
        },
        { name: "M019 Métallurgie (Ain)", id: "M019 Métallurgie (Ain)" },
        { name: "M020 Métallurgie (Aisne)", id: "M020 Métallurgie (Aisne)" },
        { name: "M021 Métallurgie (Allier)", id: "M021 Métallurgie (Allier)" },
        {
            name: "M022 Métallurgie (Alpes-Maritimes)",
            id: "M022 Métallurgie (Alpes-Maritimes)",
        },
        {
            name: "M023 Métallurgie (Ardennes)",
            id: "M023 Métallurgie (Ardennes)",
        },
        { name: "M024 Métallurgie (Aube)", id: "M024 Métallurgie (Aube)" },
        {
            name: "M025 Métallurgie (Belfort/Montbéliard)",
            id: "M025 Métallurgie (Belfort/Montbéliard)",
        },
        {
            name: "M026 Métallurgie (Bouches-du-Rhône et Alpes-de-Haute-Provence)",
            id: "M026 Métallurgie (Bouches-du-Rhône et Alpes-de-Haute-Provence)",
        },
        {
            name: "M027 Métallurgie (Calvados)",
            id: "M027 Métallurgie (Calvados)",
        },
        {
            name: "M028 Métallurgie (Charente)",
            id: "M028 Métallurgie (Charente)",
        },
        {
            name: "M029 Métallurgie (Charente-Maritime)",
            id: "M029 Métallurgie (Charente-Maritime)",
        },
        { name: "M030 Métallurgie (Cher)", id: "M030 Métallurgie (Cher)" },
        {
            name: "M031 Métallurgie (Corrèze)",
            id: "M031 Métallurgie (Corrèze)",
        },
        {
            name: "M032 Métallurgie (Côte-d'Or)",
            id: "M032 Métallurgie (Côte-d'Or)",
        },
        {
            name: "M033 Métallurgie (Côtes-d'Armor)",
            id: "M033 Métallurgie (Côtes-d'Armor)",
        },
        {
            name: "M034 Métallurgie (Dordogne)",
            id: "M034 Métallurgie (Dordogne)",
        },
        { name: "M035 Métallurgie (Doubs)", id: "M035 Métallurgie (Doubs)" },
        {
            name: "M036 Métallurgie (Drôme et Ardèche)",
            id: "M036 Métallurgie (Drôme et Ardèche)",
        },
        {
            name: "M037 Métallurgie (région de Dunkerque)",
            id: "M037 Métallurgie (région de Dunkerque)",
        },
        { name: "M038 Métallurgie (Eure)", id: "M038 Métallurgie (Eure)" },
        {
            name: "M039 Métallurgie (Eure-et-Loir)",
            id: "M039 Métallurgie (Eure-et-Loir)",
        },
        {
            name: "M040 Métallurgie (Finistère)",
            id: "M040 Métallurgie (Finistère)",
        },
        {
            name: "M041 Métallurgie (Flandres-Douaisis)",
            id: "M041 Métallurgie (Flandres-Douaisis)",
        },
        {
            name: "M042 Métallurgie (Gard et Lozère)",
            id: "M042 Métallurgie (Gard et Lozère)",
        },
        {
            name: "M043 Métallurgie (Gironde-Landes)",
            id: "M043 Métallurgie (Gironde-Landes)",
        },
        {
            name: "M044 Métallurgie (arrondissement du Havre)",
            id: "M044 Métallurgie (arrondissement du Havre)",
        },
        {
            name: "M045 Métallurgie (Hérault, Aude et Pyrénées-Orientales)",
            id: "M045 Métallurgie (Hérault, Aude et Pyrénées-Orientales)",
        },
        {
            name: "M046 Métallurgie (Ille-et-Vilaine - Morbihan)",
            id: "M046 Métallurgie (Ille-et-Vilaine - Morbihan)",
        },
        { name: "M047 Métallurgie (Indre)", id: "M047 Métallurgie (Indre)" },
        {
            name: "M048 Métallurgie (Indre-et-Loire)",
            id: "M048 Métallurgie (Indre-et-Loire)",
        },
        {
            name: "M049 Métallurgie (Isère et Hautes-Alpes)",
            id: "M049 Métallurgie (Isère et Hautes-Alpes)",
        },
        { name: "M050 Métallurgie (Jura)", id: "M050 Métallurgie (Jura)" },
        {
            name: "M051 Métallurgie (Loir-et-Cher)",
            id: "M051 Métallurgie (Loir-et-Cher)",
        },
        {
            name: "M052 Métallurgie (Loire et arrondissement d'Yssingeaux)",
            id: "M052 Métallurgie (Loire et arrondissement d'Yssingeaux)",
        },
        {
            name: "M053 Métallurgie (Loire-Atlantique)",
            id: "M053 Métallurgie (Loire-Atlantique)",
        },
        { name: "M054 Métallurgie (Loiret)", id: "M054 Métallurgie (Loiret)" },
        {
            name: "M055 Métallurgie (Lot et Garonne)",
            id: "M055 Métallurgie (Lot et Garonne)",
        },
        {
            name: "M056 Métallurgie (Maine-et-Loire)",
            id: "M056 Métallurgie (Maine-et-Loire)",
        },
        { name: "M057 Métallurgie (Manche)", id: "M057 Métallurgie (Manche)" },
        { name: "M058 Métallurgie (Marne)", id: "M058 Métallurgie (Marne)" },
        {
            name: "M059 Métallurgie (Haute-Marne et Meuse)",
            id: "M059 Métallurgie (Haute-Marne et Meuse)",
        },
        {
            name: "M060 Métallurgie (Martinique)",
            id: "M060 Métallurgie (Martinique)",
        },
        {
            name: "M061 Métallurgie (Maubeuge)",
            id: "M061 Métallurgie (Maubeuge)",
        },
        {
            name: "M062 Métallurgie (Mayenne)",
            id: "M062 Métallurgie (Mayenne)",
        },
        {
            name: "M063 Métallurgie (Meurthe-et-Moselle)",
            id: "M063 Métallurgie (Meurthe-et-Moselle)",
        },
        {
            name: "M064 Métallurgie (Midi-Pyrénées)",
            id: "M064 Métallurgie (Midi-Pyrénées)",
        },
        {
            name: "M065 Métallurgie (Moselle)",
            id: "M065 Métallurgie (Moselle)",
        },
        { name: "M066 Métallurgie (Nièvre)", id: "M066 Métallurgie (Nièvre)" },
        { name: "M067 Métallurgie (Oise)", id: "M067 Métallurgie (Oise)" },
        { name: "M069 Métallurgie (Orne)", id: "M069 Métallurgie (Orne)" },
        {
            name: "M070 Métallurgie (Pas-de-Calais)",
            id: "M070 Métallurgie (Pas-de-Calais)",
        },
        {
            name: "M071 Métallurgie (Puy-de-Dôme et Clermont-Ferrand)",
            id: "M071 Métallurgie (Puy-de-Dôme et Clermont-Ferrand)",
        },
        {
            name: "M072 Métallurgie (Hautes-Pyrénées)",
            id: "M072 Métallurgie (Hautes-Pyrénées)",
        },
        {
            name: "M073 Métallurgie (Pyrénées-Atlantiques et Seignanx)",
            id: "M073 Métallurgie (Pyrénées-Atlantiques et Seignanx)",
        },
        {
            name: "M074 Métallurgie (Région parisienne)",
            id: "M074 Métallurgie (Région parisienne)",
        },
        {
            name: "M075 Métallurgie (Bas-Rhin)",
            id: "M075 Métallurgie (Bas-Rhin)",
        },
        {
            name: "M076 Métallurgie (Haut-Rhin)",
            id: "M076 Métallurgie (Haut-Rhin)",
        },
        { name: "M077 Métallurgie (Rhône)", id: "M077 Métallurgie (Rhône)" },
        {
            name: "M078 Métallurgie (Rouen et Dieppe)",
            id: "M078 Métallurgie (Rouen et Dieppe)",
        },
        {
            name: "M079 Métallurgie (Saône-et-Loire)",
            id: "M079 Métallurgie (Saône-et-Loire)",
        },
        { name: "M080 Métallurgie (Sarthe)", id: "M080 Métallurgie (Sarthe)" },
        { name: "M081 Métallurgie (Savoie)", id: "M081 Métallurgie (Savoie)" },
        {
            name: "M082 Métallurgie (Haute-Savoie)",
            id: "M082 Métallurgie (Haute-Savoie)",
        },
        {
            name: "M083 Métallurgie (Seine-et-Marne)",
            id: "M083 Métallurgie (Seine-et-Marne)",
        },
        {
            name: "M084 Métallurgie (Deux-Sèvres)",
            id: "M084 Métallurgie (Deux-Sèvres)",
        },
        { name: "M085 Métallurgie (Somme)", id: "M085 Métallurgie (Somme)" },
        {
            name: "M086 Métallurgie (Région de Thiers)",
            id: "M086 Métallurgie (Région de Thiers)",
        },
        {
            name: "M087 Métallurgie (Valenciennes et Cambrai)",
            id: "M087 Métallurgie (Valenciennes et Cambrai)",
        },
        { name: "M088 Métallurgie (Var)", id: "M088 Métallurgie (Var)" },
        {
            name: "M089 Métallurgie (Vaucluse)",
            id: "M089 Métallurgie (Vaucluse)",
        },
        { name: "M090 Métallurgie (Vendée)", id: "M090 Métallurgie (Vendée)" },
        {
            name: "M091 Métallurgie (Haute-Vienne et Creuse)",
            id: "M091 Métallurgie (Haute-Vienne et Creuse)",
        },
        { name: "M092 Métallurgie (Vosges)", id: "M092 Métallurgie (Vosges)" },
        { name: "M093 Métallurgie (Yonne)", id: "M093 Métallurgie (Yonne)" },
        {
            name: "M094 Métreurs-vérificateurs",
            id: "M094 Métreurs-vérificateurs",
        },
        {
            name: "M095 Métiers de la transformation des grains",
            id: "M095 Métiers de la transformation des grains",
        },
        {
            name: "M096 Mines : Guyane (activités minières)",
            id: "M096 Mines : Guyane (activités minières)",
        },
        {
            name: "M097 Miroiterie (transformation et négoce du verre)",
            id: "M097 Miroiterie (transformation et négoce du verre)",
        },
        {
            name: "M098 Missions locales et PAIO",
            id: "M098 Missions locales et PAIO",
        },
        {
            name: "M099 Mode et chapellerie (fusion avec H001 : Habillement industries)",
            id: "M099 Mode et chapellerie (fusion avec H001 : Habillement industries)",
        },
        {
            name: "M100 Musique (édition : AM et Cadres) (fusion avec E003 : Édition)",
            id: "M100 Musique (édition : AM et Cadres) (fusion avec E003 : Édition)",
        },
        {
            name: "M101 Musique (édition : employés) (fusion avec E003 : Édition)",
            id: "M101 Musique (édition : employés) (fusion avec E003 : Édition)",
        },
        { name: "M102 Mutualité", id: "M102 Mutualité" },
        {
            name: "M103 Mutualité Sociale Agricole (personnel de la)",
            id: "M103 Mutualité Sociale Agricole (personnel de la)",
        },
        { name: "M104 Marchés financiers", id: "M104 Marchés financiers" },
        {
            name: "M105 Métallurgie (Haute-Saône)",
            id: "M105 Métallurgie (Haute-Saône)",
        },
        { name: "M106 Métallurgie (Vienne)", id: "M106 Métallurgie (Vienne)" },
        {
            name: "M107 Matériaux de construction (négoce)",
            id: "M107 Matériaux de construction (négoce)",
        },
        {
            name: "M108 Métallurgie (Région du Vimeu - Somme)",
            id: "M108 Métallurgie (Région du Vimeu - Somme)",
        },
        {
            name: "M109 Marpa de Picardie (personnel des)",
            id: "M109 Marpa de Picardie (personnel des)",
        },
        {
            name: "N001 Navigation (entreprises : personnel sédentaire)",
            id: "N001 Navigation (entreprises : personnel sédentaire)",
        },
        {
            name: "N002 Industrie et services nautiques (ex. Navigation de plaisance - IDCC 1423)",
            id: "N002 Industrie et services nautiques (ex. Navigation de plaisance - IDCC 1423)",
        },
        {
            name: 'N003 Navigation intérieure (transport de marchandises : personnel navigant) (remplacée par "Navigation intérieure : entreprise de transport" - N010)',
            id: 'N003 Navigation intérieure (transport de marchandises : personnel navigant) (remplacée par "Navigation intérieure : entreprise de transport" - N010)',
        },
        {
            name: 'N004 Navigation intérieure (transport de marchandises : personnel sédentaire) (remplacée par "Navigation intérieure : entreprise de transport" - N010)',
            id: 'N004 Navigation intérieure (transport de marchandises : personnel sédentaire) (remplacée par "Navigation intérieure : entreprise de transport" - N010)',
        },
        {
            name: 'N005 Navigation intérieure (transport de passagers : personnel sédentaire et navigant) (remplacée par "Navigation intérieure : entreprise de transport" - N010)',
            id: 'N005 Navigation intérieure (transport de passagers : personnel sédentaire et navigant) (remplacée par "Navigation intérieure : entreprise de transport" - N010)',
        },
        { name: "N006 Notariat", id: "N006 Notariat" },
        {
            name: "N007 Entreprises de transport et services maritimes (personnels navigants officiers)",
            id: "N007 Entreprises de transport et services maritimes (personnels navigants officiers)",
        },
        {
            name: "N008 Personnels navigants Officiers des entreprises de remorquage",
            id: "N008 Personnels navigants Officiers des entreprises de remorquage",
        },
        {
            name: "N009 Personnels navigants d'exécution des entreprises de remorquage",
            id: "N009 Personnels navigants d'exécution des entreprises de remorquage",
        },
        {
            name: "N010 Navigation intérieure : entreprise de transport",
            id: "N010 Navigation intérieure : entreprise de transport",
        },
        {
            name: "O001 Oeufs et industries en produits d'oeufs (fusion avec B061 - Boulangerie-pâtisserie : activités industrielles)",
            id: "O001 Oeufs et industries en produits d'oeufs (fusion avec B061 - Boulangerie-pâtisserie : activités industrielles)",
        },
        {
            name: "O002 Optique - lunetterie de détail",
            id: "O002 Optique - lunetterie de détail",
        },
        {
            name: "O003 Organismes de sécurité sociale",
            id: "O003 Organismes de sécurité sociale",
        },
        { name: "O004 OPA sans CCN", id: "O004 OPA sans CCN" },
        {
            name: "P002 Papeterie, fournitures de bureau (commerces de détail)",
            id: "P002 Papeterie, fournitures de bureau (commerces de détail)",
        },
        {
            name: "P003 Papiers-cartons (accords nationaux)",
            id: "P003 Papiers-cartons (accords nationaux)",
        },
        {
            name: "P006 Papiers-cartons, celluloses (production : cadres)",
            id: "P006 Papiers-cartons, celluloses (production : cadres)",
        },
        {
            name: "P007 Papiers-cartons, celluloses (production : OETAM)",
            id: "P007 Papiers-cartons, celluloses (production : OETAM)",
        },
        {
            name: "P008 Papiers-cartons, celluloses (transformation : cadres)",
            id: "P008 Papiers-cartons, celluloses (transformation : cadres)",
        },
        {
            name: "P009 Papiers-cartons, celluloses (transformation : OETAM)",
            id: "P009 Papiers-cartons, celluloses (transformation : OETAM)",
        },
        {
            name: "P011 Particulier employeur (salariés)",
            id: "P011 Particulier employeur (salariés)",
        },
        {
            name: "P012 Pâtes alimentaires sèches et couscous non préparé (fusion avec S010 : Sucreries, sucreries-distilleries et raffineries de sucre)",
            id: "P012 Pâtes alimentaires sèches et couscous non préparé (fusion avec S010 : Sucreries, sucreries-distilleries et raffineries de sucre)",
        },
        { name: "P013 Pâtisserie", id: "P013 Pâtisserie" },
        {
            name: "P015 Paysage (entreprises)",
            id: "P015 Paysage (entreprises)",
        },
        { name: "P017 Pétrole (industrie)", id: "P017 Pétrole (industrie)" },
        {
            name: "P018 Pharmacie (industrie)",
            id: "P018 Pharmacie (industrie)",
        },
        {
            name: "P019 Pharmacie (répartition pharmaceutique)",
            id: "P019 Pharmacie (répartition pharmaceutique)",
        },
        { name: "P020 Pharmacie d'officine", id: "P020 Pharmacie d'officine" },
        {
            name: "P021 Pharmacie d'officine (la Réunion)",
            id: "P021 Pharmacie d'officine (la Réunion)",
        },
        {
            name: "P022 Pharmacie, parapharmacie, produits vétérinaires (fabrication et commerce)",
            id: "P022 Pharmacie, parapharmacie, produits vétérinaires (fabrication et commerce)",
        },
        { name: "P023 Photographie", id: "P023 Photographie" },
        { name: "P024 Plasturgie", id: "P024 Plasturgie" },
        {
            name: "P025 Poissonnerie (fusion avec B056 - Boucherie, boucherie charcuterie, boucherie hippophagique)",
            id: "P025 Poissonnerie (fusion avec B056 - Boucherie, boucherie charcuterie, boucherie hippophagique)",
        },
        { name: "P026 Pompes funèbres", id: "P026 Pompes funèbres" },
        { name: "P028 Ports de plaisance", id: "P028 Ports de plaisance" },
        {
            name: "P029 Presse (agences : employés)",
            id: "P029 Presse (agences : employés)",
        },
        {
            name: "P030 Presse (agences : personnel d'encadrement)",
            id: "P030 Presse (agences : personnel d'encadrement)",
        },
        { name: "P031 Presse (portage)", id: "P031 Presse (portage)" },
        {
            name: "P034 Presse hebdomadaire régionale (cadres) ( Fusion avec P045 : Presse quotidienne régionale (encadrement))",
            id: "P034 Presse hebdomadaire régionale (cadres) ( Fusion avec P045 : Presse quotidienne régionale (encadrement))",
        },
        {
            name: "P035 Presse hebdomadaire régionale (employés)",
            id: "P035 Presse hebdomadaire régionale (employés)",
        },
        {
            name: "P040 Presse quotidienne départementale (cadres administratifs) (Fusion avec la CCN P045 : Presse quotidienne régionale (encadrement))",
            id: "P040 Presse quotidienne départementale (cadres administratifs) (Fusion avec la CCN P045 : Presse quotidienne régionale (encadrement))",
        },
        {
            name: "P041 Presse quotidienne départementale (cadres techniques) (Fusion avec la CCN P045 : Presse quotidienne régionale (encadrement))",
            id: "P041 Presse quotidienne départementale (cadres techniques) (Fusion avec la CCN P045 : Presse quotidienne régionale (encadrement))",
        },
        {
            name: "P042 Presse quotidienne départementale (employés)",
            id: "P042 Presse quotidienne départementale (employés)",
        },
        {
            name: "P043 Presse quotidienne départementale (ouvriers)",
            id: "P043 Presse quotidienne départementale (ouvriers)",
        },
        {
            name: "P044 Presse quotidienne régionale (employés)",
            id: "P044 Presse quotidienne régionale (employés)",
        },
        {
            name: "P045 Presse quotidienne régionale (encadrement)",
            id: "P045 Presse quotidienne régionale (encadrement)",
        },
        {
            name: "P046 Presse quotidienne régionale (ouvriers)",
            id: "P046 Presse quotidienne régionale (ouvriers)",
        },
        {
            name: "P047 Presse quotidienne régionale (vendeurs administratifs salariés portage : VASP)",
            id: "P047 Presse quotidienne régionale (vendeurs administratifs salariés portage : VASP)",
        },
        {
            name: "P048 Prestataires de services",
            id: "P048 Prestataires de services",
        },
        {
            name: "P049 Prévention et sécurité",
            id: "P049 Prévention et sécurité",
        },
        {
            name: "P050 Produits alimentaires élaborés (industries)",
            id: "P050 Produits alimentaires élaborés (industries)",
        },
        {
            name: "P051 Produits du sol, engrais et produits connexes (négoce et industrie)",
            id: "P051 Produits du sol, engrais et produits connexes (négoce et industrie)",
        },
        {
            name: "P053 Promotion immobilière",
            id: "P053 Promotion immobilière",
        },
        {
            name: "P054 Propreté et services associés (entreprises de)",
            id: "P054 Propreté et services associés (entreprises de)",
        },
        { name: "P055 Publicité", id: "P055 Publicité" },
        {
            name: "P056 Poste (activités postales)",
            id: "P056 Poste (activités postales)",
        },
        {
            name: "P057 Production Agricole du Calvados",
            id: "P057 Production Agricole du Calvados",
        },
        { name: "P058 Pôle Emploi", id: "P058 Pôle Emploi" },
        { name: "P059 Ports et manutention", id: "P059 Ports et manutention" },
        {
            name: "P062 Personnel du Culte chrétien sans CCN",
            id: "P062 Personnel du Culte chrétien sans CCN",
        },
        {
            name: "P063 Personnel du Culte bouddhiste sans CCN",
            id: "P063 Personnel du Culte bouddhiste sans CCN",
        },
        {
            name: "P064 Personnel du Culte hindou sans CCN",
            id: "P064 Personnel du Culte hindou sans CCN",
        },
        {
            name: "P065 Personnel du Culte musulman sans CCN",
            id: "P065 Personnel du Culte musulman sans CCN",
        },
        {
            name: "P066 Personnel du Culte Témoins de Jéhovah sans CCN",
            id: "P066 Personnel du Culte Témoins de Jéhovah sans CCN",
        },
        {
            name: "P067 Personnel du Culte judaïque sans CCN",
            id: "P067 Personnel du Culte judaïque sans CCN",
        },
        {
            name: "P068 Personnel du Culte tenrikyo sans CCN",
            id: "P068 Personnel du Culte tenrikyo sans CCN",
        },
        {
            name: "P069 Personnel des autres Cultes sans CCN",
            id: "P069 Personnel des autres Cultes sans CCN",
        },
        {
            name: "P070 Presse quotidienne parisienne (employés)",
            id: "P070 Presse quotidienne parisienne (employés)",
        },
        {
            name: "P071 Presse quotidienne parisienne (cadres administratifs)",
            id: "P071 Presse quotidienne parisienne (cadres administratifs)",
        },
        {
            name: "P072 Pêche de loisir et protection du milieu aquatique (structures associatives)",
            id: "P072 Pêche de loisir et protection du milieu aquatique (structures associatives)",
        },
        {
            name: "P073 Personnel des restaurants des PTT",
            id: "P073 Personnel des restaurants des PTT",
        },
        {
            name: "P074 Personnel des comités d'établissement et du comité central d'entreprise de la SNCF",
            id: "P074 Personnel des comités d'établissement et du comité central d'entreprise de la SNCF",
        },
        {
            name: "P075 Personnel de la Croix-Rouge française",
            id: "P075 Personnel de la Croix-Rouge française",
        },
        {
            name: "P076 Salariés en portage salarial",
            id: "P076 Salariés en portage salarial",
        },
        {
            name: "P077 Agences de presse (employés, techniciens et cadres)",
            id: "P077 Agences de presse (employés, techniciens et cadres)",
        },
        {
            name: "P078 Personnel des restaurants inter entreprises associatifs de La Poste et de France Telecom",
            id: "P078 Personnel des restaurants inter entreprises associatifs de La Poste et de France Telecom",
        },
        {
            name: "P079 Pêche professionnelle maritime",
            id: "P079 Pêche professionnelle maritime",
        },
        {
            name: "P080 Editeurs de la presse magazine (employés et cadres) (Fusion avec P084 : Presse d'information spécialisée (employés, techniciens agents de maîtrise et cadres))",
            id: "P080 Editeurs de la presse magazine (employés et cadres) (Fusion avec P084 : Presse d'information spécialisée (employés, techniciens agents de maîtrise et cadres))",
        },
        {
            name: "P081 Presse quotidienne parisienne (ouvriers)",
            id: "P081 Presse quotidienne parisienne (ouvriers)",
        },
        {
            name: "P082 Offices publics de l'habitat - OPH (personnel)",
            id: "P082 Offices publics de l'habitat - OPH (personnel)",
        },
        { name: "P083 PROFMED sans CCN", id: "P083 PROFMED sans CCN" },
        {
            name: "P084 Presse d'information spécialisée (employés, techniciens agents de maîtrise et cadres)",
            id: "P084 Presse d'information spécialisée (employés, techniciens agents de maîtrise et cadres)",
        },
        {
            name: "P085 Personnel des services administratifs et d'animation culturelle de l'Association Martiniquaise d'Education Populaire (AMEP)",
            id: "P085 Personnel des services administratifs et d'animation culturelle de l'Association Martiniquaise d'Education Populaire (AMEP)",
        },
        {
            name: "P086 Papiers-cartons (distribution et commerce de gros)",
            id: "P086 Papiers-cartons (distribution et commerce de gros)",
        },
        {
            name: "P087 Personnel des entreprises concessionnaires des marchés découverts de la ville de Paris",
            id: "P087 Personnel des entreprises concessionnaires des marchés découverts de la ville de Paris",
        },
        {
            name: "P088 Presse quotidienne et hebdomadaire en régions",
            id: "P088 Presse quotidienne et hebdomadaire en régions",
        },
        {
            name: "Q001 Quincaillerie (commerces nationale : cadres)",
            id: "Q001 Quincaillerie (commerces nationale : cadres)",
        },
        {
            name: "Q002 Quincaillerie (commerces nationale : employés et agents de maîtrise)",
            id: "Q002 Quincaillerie (commerces nationale : employés et agents de maîtrise)",
        },
        { name: "Q003 Régies de quartier", id: "Q003 Régies de quartier" },
        { name: "R001 Radiodiffusion", id: "R001 Radiodiffusion" },
        {
            name: "R002 Récupération (industries et commerces)",
            id: "R002 Récupération (industries et commerces)",
        },
        {
            name: "R003 Reprographie (fusion avec P002 : Papeterie, fournitures de bureau - commerces de détail)",
            id: "R003 Reprographie (fusion avec P002 : Papeterie, fournitures de bureau - commerces de détail)",
        },
        { name: "R004 Restaurants (chaîne)", id: "R004 Restaurants (chaîne)" },
        {
            name: "R005 Restauration de collectivités",
            id: "R005 Restauration de collectivités",
        },
        {
            name: "R006 Restauration ferroviaire",
            id: "R006 Restauration ferroviaire",
        },
        { name: "R007 Restauration rapide", id: "R007 Restauration rapide" },
        {
            name: "R008 Retraite et prévoyance des cadres : convention du 14 mars 1947",
            id: "R008 Retraite et prévoyance des cadres : convention du 14 mars 1947",
        },
        {
            name: "R009 Recherches privées (agences)",
            id: "R009 Recherches privées (agences)",
        },
        { name: "R010 Rugby professionnel", id: "R010 Rugby professionnel" },
        {
            name: "S001 Sanitaire, social et médico-social à but non lucratif (accords de branche du secteur)",
            id: "S001 Sanitaire, social et médico-social à but non lucratif (accords de branche du secteur)",
        },
        {
            name: "S002 Sélection et reproduction animale (entreprises)",
            id: "S002 Sélection et reproduction animale (entreprises)",
        },
        {
            name: "S003 Sérigraphie et procédés d'impression numérique connexes (industrie) (fusion avec I003 : Imprimeries de labeur et industries graphiques)",
            id: "S003 Sérigraphie et procédés d'impression numérique connexes (industrie) (fusion avec I003 : Imprimeries de labeur et industries graphiques)",
        },
        {
            name: "S004 Service à la personne (entreprises)",
            id: "S004 Service à la personne (entreprises)",
        },
        { name: "S005 Sidérurgie", id: "S005 Sidérurgie" },
        {
            name: "S006 Sociaux et socioculturels (centres) et autres acteurs du lien social (associations)",
            id: "S006 Sociaux et socioculturels (centres) et autres acteurs du lien social (associations)",
        },
        { name: "S007 Sociétés financières", id: "S007 Sociétés financières" },
        { name: "S008 Sport", id: "S008 Sport" },
        {
            name: "S009 Sport (commerce des articles de sport et équipements de loisirs)",
            id: "S009 Sport (commerce des articles de sport et équipements de loisirs)",
        },
        {
            name: "S010 Sucreries, sucreries-distilleries et raffineries de sucre",
            id: "S010 Sucreries, sucreries-distilleries et raffineries de sucre",
        },
        {
            name: "S011 Surveillance de la qualité de l'air (associations agréées) (fusion avec B065 : Bureaux d'études techniques)",
            id: "S011 Surveillance de la qualité de l'air (associations agréées) (fusion avec B065 : Bureaux d'études techniques)",
        },
        {
            name: "S012 Spectacle vivant (structures mobiles)",
            id: "S012 Spectacle vivant (structures mobiles)",
        },
        {
            name: "S013 Spectacle vivant public et privé (accords interbranches )",
            id: "S013 Spectacle vivant public et privé (accords interbranches )",
        },
        {
            name: "S014 Scieries agricoles et activités connexes de Franche-Comté",
            id: "S014 Scieries agricoles et activités connexes de Franche-Comté",
        },
        {
            name: "S015 Spectacle vivant (secteur privé)",
            id: "S015 Spectacle vivant (secteur privé)",
        },
        {
            name: "S016 Personnel du parti socialiste",
            id: "S016 Personnel du parti socialiste",
        },
        {
            name: "S017 Sociétés d'aménagement foncier et d'établissement rural (SAFER)",
            id: "S017 Sociétés d'aménagement foncier et d'établissement rural (SAFER)",
        },
        {
            name: "S018 Stations-service de Guadeloupe",
            id: "S018 Stations-service de Guadeloupe",
        },
        {
            name: "S019 Stations-service de Martinique",
            id: "S019 Stations-service de Martinique",
        },
        {
            name: "S020 Sucreries, sucreries-distilleries et distilleries de Guadeloupe (personnel ouvrier, employé et agent de maîtrise)",
            id: "S020 Sucreries, sucreries-distilleries et distilleries de Guadeloupe (personnel ouvrier, employé et agent de maîtrise)",
        },
        {
            name: "T001 Tailleurs sur mesure (région parisienne) (fusion avec C084 : Couture parisienne)",
            id: "T001 Tailleurs sur mesure (région parisienne) (fusion avec C084 : Couture parisienne)",
        },
        { name: "T002 Taxis", id: "T002 Taxis" },
        { name: "T003 Télécommunications", id: "T003 Télécommunications" },
        {
            name: "T004 Remontées mécaniques (ex : Téléphériques et engins de remontées mécaniques)",
            id: "T004 Remontées mécaniques (ex : Téléphériques et engins de remontées mécaniques)",
        },
        {
            name: "T005 Textiles (industries)",
            id: "T005 Textiles (industries)",
        },
        {
            name: "T006 Textiles artificiels et synthétiques et produits assimilés (fusion avec T005 : Textiles (industries))",
            id: "T006 Textiles artificiels et synthétiques et produits assimilés (fusion avec T005 : Textiles (industries))",
        },
        {
            name: "T007 Thalassothérapie (centres)",
            id: "T007 Thalassothérapie (centres)",
        },
        {
            name: "T009 Thermalisme (fusion avec H014 - Hospitalisation privée à but lucratif)",
            id: "T009 Thermalisme (fusion avec H014 - Hospitalisation privée à but lucratif)",
        },
        {
            name: "T010 Thermiques (équipements : cadres et assimilés)",
            id: "T010 Thermiques (équipements : cadres et assimilés)",
        },
        {
            name: "T011 Thermiques (équipements : OETAM)",
            id: "T011 Thermiques (équipements : OETAM)",
        },
        {
            name: "T013 Tourisme (agences de voyages et de tourisme)",
            id: "T013 Tourisme (agences de voyages et de tourisme)",
        },
        {
            name: "T014 Tourisme (guides accompagnateurs) (fusion avec T013 - Tourisme : agences de voyages et de tourisme)",
            id: "T014 Tourisme (guides accompagnateurs) (fusion avec T013 - Tourisme : agences de voyages et de tourisme)",
        },
        {
            name: "T015 Tourisme (guides et accompagnateurs en milieu amazonien)",
            id: "T015 Tourisme (guides et accompagnateurs en milieu amazonien)",
        },
        {
            name: "T016 Tourisme (guides interprètes : région parisienne) (fusion avec T013 : Tourisme agences de voyages et de tourisme)",
            id: "T016 Tourisme (guides interprètes : région parisienne) (fusion avec T013 : Tourisme agences de voyages et de tourisme)",
        },
        {
            name: "T017 Tourisme (organismes)",
            id: "T017 Tourisme (organismes)",
        },
        {
            name: "T018 Tourisme social et familial",
            id: "T018 Tourisme social et familial",
        },
        {
            name: "T019 Matériels agricoles, de BTP et de manutention (maintenance,  distribution et location)",
            id: "T019 Matériels agricoles, de BTP et de manutention (maintenance,  distribution et location)",
        },
        {
            name: "T020 Transport aérien (personnel au sol)",
            id: "T020 Transport aérien (personnel au sol)",
        },
        {
            name: "T021 Transport aérien (personnel navigant)",
            id: "T021 Transport aérien (personnel navigant)",
        },
        {
            name: "T022 Transport de fonds et de valeurs",
            id: "T022 Transport de fonds et de valeurs",
        },
        {
            name: "T023 Transport ferroviaire et activités associées",
            id: "T023 Transport ferroviaire et activités associées",
        },
        {
            name: "T024 Transport sanitaire (Martinique)",
            id: "T024 Transport sanitaire (Martinique)",
        },
        {
            name: "T025 Transports publics urbains (réseaux de voyageurs)",
            id: "T025 Transports publics urbains (réseaux de voyageurs)",
        },
        {
            name: "T026 Transports routiers et activités auxiliaires du transport",
            id: "T026 Transports routiers et activités auxiliaires du transport",
        },
        {
            name: "T027 Travail aérien (personnel navigant des essais et réception)",
            id: "T027 Travail aérien (personnel navigant des essais et réception)",
        },
        {
            name: "T028 Travail temporaire (salariés intérimaires)",
            id: "T028 Travail temporaire (salariés intérimaires)",
        },
        {
            name: "T029 Travail temporaire (salariés permanents)",
            id: "T029 Travail temporaire (salariés permanents)",
        },
        {
            name: "T031 Travaux publics (accords nationaux)",
            id: "T031 Travaux publics (accords nationaux)",
        },
        {
            name: "T032 Travaux publics (Cadres)",
            id: "T032 Travaux publics (Cadres)",
        },
        {
            name: "T033 Travaux publics (ETAM)",
            id: "T033 Travaux publics (ETAM)",
        },
        {
            name: "T034 Travaux publics (Ouvriers)",
            id: "T034 Travaux publics (Ouvriers)",
        },
        {
            name: "T035 Travaux publics (Ouvriers : Isère)",
            id: "T035 Travaux publics (Ouvriers : Isère)",
        },
        {
            name: "T036 Travaux publics (Ouvriers : Savoie)",
            id: "T036 Travaux publics (Ouvriers : Savoie)",
        },
        {
            name: "T037 Tuiles et briques (industries)",
            id: "T037 Tuiles et briques (industries)",
        },
        {
            name: "T038 Théâtre National de Chaillot",
            id: "T038 Théâtre National de Chaillot",
        },
        {
            name: "T039 Transport maritime (officiers)",
            id: "T039 Transport maritime (officiers)",
        },
        {
            name: "T040 Transport maritime (personnel navigant d'exécution)",
            id: "T040 Transport maritime (personnel navigant d'exécution)",
        },
        {
            name: "U001 Universités et instituts catholiques de France",
            id: "U001 Universités et instituts catholiques de France",
        },
        {
            name: "V001 Entreprises du commerce à distance (ex : vente à distance)",
            id: "V001 Entreprises du commerce à distance (ex : vente à distance)",
        },
        {
            name: "V002 Cristal, verre, vitrail (professions regroupées)",
            id: "V002 Cristal, verre, vitrail (professions regroupées)",
        },
        {
            name: "V003 Verre (fabrication mécanique)",
            id: "V003 Verre (fabrication mécanique)",
        },
        {
            name: "V004 Verre (métiers) (fusion avec V002 : Cristal, verre, vitrail (professions regroupées))",
            id: "V004 Verre (métiers) (fusion avec V002 : Cristal, verre, vitrail (professions regroupées))",
        },
        {
            name: "V005 Verrerie au chalumeau : travail mécanique (fusion avec V002 : Cristal, verre, vitrail (professions regroupées))",
            id: "V005 Verrerie au chalumeau : travail mécanique (fusion avec V002 : Cristal, verre, vitrail (professions regroupées))",
        },
        {
            name: "V006 Vétérinaires (personnel salarié des cabinets et cliniques vétérinaires)",
            id: "V006 Vétérinaires (personnel salarié des cabinets et cliniques vétérinaires)",
        },
        {
            name: "V007 Vétérinaires (praticiens salariés) (fusion avec V006 : Vétérinaires - personnel salarié des cabinets et cliniques vétérinaires)",
            id: "V007 Vétérinaires (praticiens salariés) (fusion avec V006 : Vétérinaires - personnel salarié des cabinets et cliniques vétérinaires)",
        },
        {
            name: "V008 Viandes (industries et commerce en gros)",
            id: "V008 Viandes (industries et commerce en gros)",
        },
        {
            name: "V009 Vins (caves coopératives vinicoles et leurs unions)",
            id: "V009 Vins (caves coopératives vinicoles et leurs unions)",
        },
        {
            name: "V010 Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France ",
            id: "V010 Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France ",
        },
        {
            name: "V011 Vitrail (fusion avec V002 : Cristal, verre, vitrail (professions regroupées))",
            id: "V011 Vitrail (fusion avec V002 : Cristal, verre, vitrail (professions regroupées))",
        },
        {
            name: "V012 Voies ferrées d'intérêt local",
            id: "V012 Voies ferrées d'intérêt local",
        },
        {
            name: "V013 Volailles (industries de la transformation)",
            id: "V013 Volailles (industries de la transformation)",
        },
        {
            name: "V014 Voyageurs, représentants, placiers (VRP)",
            id: "V014 Voyageurs, représentants, placiers (VRP)",
        },
        {
            name: "V015 Exploitations agricoles de la zone viticole du département de l'Aude",
            id: "V015 Exploitations agricoles de la zone viticole du département de l'Aude",
        },
        {
            name: "V016 VDI (Vendeur à domicile)",
            id: "V016 VDI (Vendeur à domicile)",
        },
        {
            name: "Z001 Zoos (Parcs et jardins zoologiques privés ouverts au public)",
            id: "Z001 Zoos (Parcs et jardins zoologiques privés ouverts au public)",
        },
        {
            name: "Z002 Entreprises du spectacle sans CCN",
            id: "Z002 Entreprises du spectacle sans CCN",
        },
        { name: "ZA001 Accord d'entreprise", id: "ZA001 Accord d'entreprise" },
        { name: "ZA002 Accord d'entreprise", id: "ZA002 Accord d'entreprise" },
    ];
}
